/* eslint-disable no-unused-expressions */
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Header } from "../components";
import { useMentorContext } from "../contexts/MentorContextProvider";
import { baseUrl } from "../apis/base_url";
import { useAuthContext } from "../contexts/AuthContextProvider";

const MentorsTasksView = () => {
  const { mentorId, taskId } = useParams();
  const [mentorTaskData, setMentorTaskData] = useState(null);
  const { allMentors, getAllMentors } = useMentorContext();
  const { currentUser } = useAuthContext();

  const getMentor = useCallback(async () => {
    await getAllMentors(currentUser["token"]);
    // eslint-disable-next-line array-callback-return
  }, [getAllMentors, currentUser]);

  const getMentorTask = useCallback(async () => {
    const mentor = allMentors.find(
      (item) => item["id"].toString() === mentorId.toString()
    );
    const mentorTask = mentor["tasks"].find(
      (item) => item["id"].toString() === taskId.toString()
    );
    setMentorTaskData(mentorTask);
  }, [allMentors, mentorId, taskId]);

  useEffect(() => getMentor(), [getMentor]);
  useEffect(() => getMentorTask(), [getMentorTask]);

  return (
    <div className='pt-24 pb-10 bg-gray-100 md:py-10 '>
      {allMentors && mentorTaskData ? (
        <div className='p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl'>
          <Header
            category='Interest Category'
            title={`Mentor's Task Details`}
          />
          {/* image */}
          <div className='w-full mb-4'>
            <div className='flex items-center justify-center gap-2'>
              <img
                className='w-48 h-48 rounded-full'
                src={baseUrl + "/" + mentorTaskData["icon"]}
                alt='employee'
              />
            </div>
          </div>

          {/* table */}
          <div className='flex flex-col'>
            <div className='overflow-x-auto'>
              <div className='p-1.5 w-full inline-block align-middle'>
                <div className='overflow-hidden border rounded-lg'>
                  <table className='min-w-full divide-y divide-gray-200'>
                    <tbody className='divide-y divide-gray-200'>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Name
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          {mentorTaskData["task"]}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl'>
          <h5 className='text-center font-bold text-md'>Loading ....</h5>
        </div>
      )}
    </div>
  );
};
export default MentorsTasksView;
