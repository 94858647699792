import React, { useEffect } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { MdKeyboardArrowDown } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import avatar from "../data/avatar.jpg";
import { Cart, Chat, Notification, UserProfile } from ".";
import { useThemeContext } from "../contexts/ThemeContextProvider";
import { useAuthContext } from "../contexts/AuthContextProvider";

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <TooltipComponent content={title} position='BottomCenter'>
    <button
      type='button'
      onClick={() => customFunc()}
      style={{ color }}
      className='relative p-3 text-xl rounded-full hover:bg-light-gray'>
      <span
        style={{ background: dotColor }}
        className='absolute inline-flex w-2 h-2 rounded-full right-2 top-2'
      />
      {icon}
    </button>
  </TooltipComponent>
);

const Navbar = () => {
  const {
    currentColor,
    activeMenu,
    setActiveMenu,
    handleClick,
    isClicked,
    setScreenSize,
    screenSize,
  } = useThemeContext();
  const { getCurrentUser, currentUser } = useAuthContext();

  // useEffect(() => {
  //   const handleResize = () => setScreenSize(window.innerWidth);
  //   getCurrentUser();
  //   window.addEventListener("resize", handleResize);

  //   handleResize();

  //   return () => window.removeEventListener("resize", handleResize);
  // }, [
  // ]);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    // Only call getCurrentUser if it hasn't been called before
    if (!currentUser) {
      getCurrentUser();
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [currentUser, getCurrentUser, setScreenSize]);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize, setActiveMenu]);

  const handleActiveMenu = () => setActiveMenu(!activeMenu);

  return (
    <div className='relative flex justify-between p-2 md:ml-6 md:mr-6'>
      <NavButton
        title='Menu'
        customFunc={handleActiveMenu}
        color={currentColor}
        icon={<AiOutlineMenu />}
      />
      <div className='flex'>
        {/* <NavButton title="Cart" customFunc={() => handleClick('cart')} color={currentColor} icon={<FiShoppingCart />} />
        <NavButton title="Chat" dotColor="#03C9D7" customFunc={() => handleClick('chat')} color={currentColor} icon={<BsChatLeft />} />
        <NavButton title="Notification" dotColor="rgb(254, 201, 15)" customFunc={() => handleClick('notification')} color={currentColor} icon={<RiNotification3Line />} /> */}
        <TooltipComponent content='Profile' position='BottomCenter'>
          <div
            className='flex items-center gap-2 p-1 rounded-lg cursor-pointer hover:bg-light-gray'
            onClick={() => handleClick("userProfile")}>
            <img
              className='w-8 h-8 rounded-full'
              src={avatar}
              alt='user-profile'
            />
            <p>
              <span className='text-gray-400 text-14'>Hi,</span>{" "}
              <span className='ml-1 font-bold text-gray-400 text-14'>
                {currentUser && currentUser["name"]}
              </span>
            </p>
            <MdKeyboardArrowDown className='text-gray-400 text-14' />
          </div>
        </TooltipComponent>

        {isClicked.cart && <Cart />}
        {isClicked.chat && <Chat />}
        {isClicked.notification && <Notification />}
        {isClicked.userProfile && <UserProfile currentUser={currentUser} />}
      </div>
    </div>
  );
};

export default Navbar;
