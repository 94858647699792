import React, { useEffect } from "react";
import { Header } from "../components";
import { useDashboardContext } from "../contexts/DashboardContextProvider";
import { useAuthContext } from "../contexts/AuthContextProvider";
import { CChart } from "@coreui/react-chartjs";

const EmployeeChart = () => {
  const { dashboardData, getAllDashboard } = useDashboardContext();
  const { currentUser } = useAuthContext();

  useEffect(() => {
    getAllDashboard(currentUser["token"]);
  }, [getAllDashboard, currentUser]);

  return (
    <div>
      {dashboardData ? (
        <div className='pt-24 pb-10 bg-gray-100 md:py-12'>
          <div className='p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl'>
            <Header category='Page' title='User Charts' />

            <div className='flex flex-wrap justify-center gap-3 lg:flex-shrink lg:flex-nowrap'>
              <div className='w-[100%] bg-white dark:text-gray-200 dark:bg-secondary-dark-bg rounded-2xl'>
                {/* graph 2 */}
                <div className='flex  flex-wrap justify-center gap-5 mt-10'>
                  {/* average score per day by interests*/}
                  <div className='md:w-[47%] w-[100%]'>
                    <p className='text-md font-semibold'>
                      Average Score Per Day By Interests
                    </p>
                    <CChart
                      type='bar'
                      data={{
                        labels: ["M", "T", "W", "T", "F", "S", "S"],
                        datasets: [
                          {
                            label: "01/08",
                            backgroundColor: "blue",
                            data: [25.0, 21.59, 7.95, 1.14, 0.0, 0.0, 0.0],
                          },
                          {
                            label: "27/07",
                            backgroundColor: "#f33d",
                            data: [27.27, 15.91, 11.36, 7.95, 7.95, 4.71, 0.0],
                          },
                          {
                            label: "20/07",
                            backgroundColor: "yellow",
                            data: [2.27, 17.05, 14.77, 10.23, 3.41, 4.71, 0.0],
                          },
                          {
                            label: "12/07",
                            backgroundColor: "green",
                            data: [3.41, 17.05, 14.77, 10.23, 3.41, 4.71, 0.0],
                          },
                        ],
                      }}
                      labels='months'
                      options={{
                        plugins: {
                          legend: {
                            labels: {
                              //   color: getStyle('--cui-body-color'),
                            },
                          },
                        },
                        scales: {
                          x: {
                            stacked: true,
                            // grid: {
                            //   color: getStyle('--cui-border-color-translucent'),
                            // },
                            // ticks: {
                            //   color: getStyle('--cui-body-color'),
                            // },
                          },
                          y: {
                            stacked: true,
                            // grid: {
                            //   color: getStyle('--cui-border-color-translucent'),
                            // },
                            // ticks: {
                            //   color: getStyle('--cui-body-color'),
                            // },
                          },
                        },
                      }}
                    />
                  </div>
                  {/* average score per day */}
                  <div className='md:w-[47%] w-[100%]'>
                    <p className='text-md font-semibold'>
                      Average Score Per Day
                    </p>
                    <CChart
                      type='bar'
                      data={{
                        labels: ["M", "T", "W", "T", "F", "S", "S"],
                        datasets: [
                          {
                            label: "01/08",
                            backgroundColor: "blue",
                            data: [25.0, 21.59, 7.95, 1.14, 0.0, 0.0, 0.0],
                          },
                          {
                            label: "27/07",
                            backgroundColor: "#f33d",
                            data: [27.27, 15.91, 11.36, 7.95, 7.95, 4.71, 0.0],
                          },
                          {
                            label: "20/07",
                            backgroundColor: "yellow",
                            data: [2.27, 17.05, 14.77, 10.23, 3.41, 4.71, 0.0],
                          },
                          {
                            label: "12/07",
                            backgroundColor: "green",
                            data: [3.41, 17.05, 14.77, 10.23, 3.41, 4.71, 0.0],
                          },
                        ],
                      }}
                      labels='months'
                      options={{
                        plugins: {
                          legend: {
                            labels: {
                              //   color: getStyle('--cui-body-color'),
                            },
                          },
                        },
                        scales: {
                          x: {
                            // grid: {
                            //   color: getStyle('--cui-border-color-translucent'),
                            // },
                            // ticks: {
                            //   color: getStyle('--cui-body-color'),
                            // },
                          },
                          y: {
                            // grid: {
                            //   color: getStyle('--cui-border-color-translucent'),
                            // },
                            // ticks: {
                            //   color: getStyle('--cui-body-color'),
                            // },
                          },
                        },
                      }}
                    />
                  </div>
                  {/* average score per week */}
                  <div className='md:w-[47%] w-[100%]'>
                    <p className='text-md font-semibold'>
                      Average Score Per Week
                    </p>
                    <CChart
                      type='bar'
                      data={{
                        labels: ["01/08", "27/07", "20/07", "12/07"],
                        datasets: [
                          {
                            label: "01/08",
                            backgroundColor: "blue",
                            data: [7.95, 10.74, 7.49, 7.65],
                          },
                        ],
                      }}
                      labels='months'
                      options={{
                        plugins: {
                          legend: {
                            labels: {
                              //   color: getStyle('--cui-body-color'),
                            },
                          },
                        },
                        scales: {
                          x: {
                            // grid: {
                            //   color: getStyle('--cui-border-color-translucent'),
                            // },
                            // ticks: {
                            //   color: getStyle('--cui-body-color'),
                            // },
                          },
                          y: {
                            // grid: {
                            //   color: getStyle('--cui-border-color-translucent'),
                            // },
                            // ticks: {
                            //   color: getStyle('--cui-body-color'),
                            // },
                          },
                        },
                      }}
                    />
                  </div>

                  {/* time spend and point set */}
                  <div className='md:w-[47%] w-[100%]'>
                    <p className='text-md font-semibold'>
                      Time Spend And Points Set
                    </p>
                    <CChart
                      type='bar'
                      data={{
                        labels: [
                          "Chores",
                          "Family",
                          "Finance",
                          "Health",
                          "Hobbies",
                          "Sleep",
                          "Spirituality",
                        ],
                        datasets: [
                          {
                            label: "Time Spend",
                            backgroundColor: "blue",
                            data: [5, 30, 500, 101, 25, 480, 72],
                          },
                          {
                            label: "Point Set",
                            backgroundColor: "#f33d",
                            data: [2, 5, 8, 35, 1, 5, 28],
                          },
                        ],
                      }}
                      labels='months'
                      options={{
                        plugins: {
                          legend: {
                            labels: {
                              //   color: getStyle('--cui-body-color'),
                            },
                          },
                        },
                        scales: {
                          x: {
                            // grid: {
                            //   color: getStyle('--cui-border-color-translucent'),
                            // },
                            // ticks: {
                            //   color: getStyle('--cui-body-color'),
                            // },
                          },
                          y: {
                            // grid: {
                            //   color: getStyle('--cui-border-color-translucent'),
                            // },
                            // ticks: {
                            //   color: getStyle('--cui-body-color'),
                            // },
                          },
                        },
                      }}
                    />
                  </div>

                  {/* time spend pie*/}
                  <div className='md:w-[47%] w-[100%]'>
                    <p className='text-md font-semibold'>
                      Time Spend Pie Chart
                    </p>
                    <CChart
                      type='pie'
                      data={{
                        labels: [
                          "Chores",
                          "Family",
                          "Finance",
                          "Health",
                          "Hobbies",
                          "Sleep",
                          "Spirituality",
                        ],
                        datasets: [
                          {
                            label: "Time Spend",
                            backgroundColor: [
                              "red",
                              "green",
                              "blue",
                              "yellow",
                              "cyan",
                              "purple",
                              "black",
                            ],
                            data: [5, 30, 500, 101, 25, 480, 72],
                          },
                          // {
                          //   label: "Point Set",
                          //   backgroundColor: "#f33d",
                          //   data: [2, 5, 8, 35, 1, 5, 28],
                          // },
                        ],
                      }}
                      labels='months'
                      options={{
                        plugins: {
                          legend: {
                            labels: {
                              //   color: getStyle('--cui-body-color'),
                            },
                          },
                        },
                        scales: {
                          x: {
                            // grid: {
                            //   color: getStyle('--cui-border-color-translucent'),
                            // },
                            // ticks: {
                            //   color: getStyle('--cui-body-color'),
                            // },
                          },
                          y: {
                            // grid: {
                            //   color: getStyle('--cui-border-color-translucent'),
                            // },
                            // ticks: {
                            //   color: getStyle('--cui-body-color'),
                            // },
                          },
                        },
                      }}
                    />
                  </div>

                  {/* point set pie*/}
                  <div className='md:w-[47%] w-[100%]'>
                    <p className='text-md font-semibold'>Point Set Pie Chart</p>
                    <CChart
                      type='pie'
                      data={{
                        labels: [
                          "Chores",
                          "Family",
                          "Finance",
                          "Health",
                          "Hobbies",
                          "Sleep",
                          "Spirituality",
                        ],
                        datasets: [
                          {
                            label: "Point Set",

                            backgroundColor: [
                              "red",
                              "green",
                              "blue",
                              "yellow",
                              "cyan",
                              "purple",
                              "black",
                            ],
                            data: [2, 5, 8, 35, 1, 5, 28],
                          },
                        ],
                      }}
                      labels='months'
                      options={{
                        plugins: {
                          legend: {
                            labels: {
                              //   color: getStyle('--cui-body-color'),
                            },
                          },
                        },
                        scales: {
                          x: {
                            // grid: {
                            //   color: getStyle('--cui-border-color-translucent'),
                            // },
                            // ticks: {
                            //   color: getStyle('--cui-body-color'),
                            // },
                          },
                          y: {
                            // grid: {
                            //   color: getStyle('--cui-border-color-translucent'),
                            // },
                            // ticks: {
                            //   color: getStyle('--cui-body-color'),
                            // },
                          },
                        },
                      }}
                    />
                  </div>

                  {/* time spend and point set */}
                  <div className='w-[80%]'>
                    <p className='text-md font-semibold'>
                      Time Spend And Points Set
                    </p>
                    <CChart
                      type='line'
                      data={{
                        labels: [
                          "Chores",
                          "Family",
                          "Finance",
                          "Health",
                          "Hobbies",
                          "Sleep",
                          "Spirituality",
                        ],
                        datasets: [
                          {
                            label: "Time Spend",
                            backgroundColor: "rgba(54, 162, 235, 0.5)",
                            borderColor: "rgba(54, 162, 235, 1)",
                            fill: true,
                            data: [5, 30, 500, 101, 25, 480, 72],
                          },
                          {
                            label: "Point Set",
                            backgroundColor: "rgba(255, 2, 25, 0.5)",
                            borderColor: "rgba(255, 2, 25, 1)",
                            fill: true,
                            data: [2, 5, 8, 35, 1, 5, 28],
                          },
                        ],
                      }}
                      labels='months'
                      options={{
                        plugins: {
                          legend: {
                            labels: {
                              //   color: getStyle('--cui-body-color'),
                            },
                          },
                        },
                        scales: {
                          x: {
                            // grid: {
                            //   color: getStyle('--cui-border-color-translucent'),
                            // },
                            // ticks: {
                            //   color: getStyle('--cui-body-color'),
                            // },
                          },
                          y: {
                            // grid: {
                            //   color: getStyle('--cui-border-color-translucent'),
                            // },
                            // ticks: {
                            //   color: getStyle('--cui-body-color'),
                            // },
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl'>
          <h5 className='font-bold text-center text-md'>Loading ....</h5>
        </div>
      )}
    </div>
  );
};

export default EmployeeChart;
