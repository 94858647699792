import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import App from "./App";
import { ContextProvider } from "./contexts/ThemeContextProvider";
import { AuthContextProvider } from "./contexts/AuthContextProvider";
import { InterestsContextProvider } from "./contexts/InterestsContextProvider";
import { UsersContextProvider } from "./contexts/UsersContextProvider";
import { MentorContextProvider } from "./contexts/MentorContextProvider";
import { DashboardContextProvider } from "./contexts/DashboardContextProvider";
import { UserFeedbackContextProvider } from "./contexts/UserFeedbackProvider";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { TasksContextProvider } from "./contexts/TasksContextProvider";

ReactDOM.render(
  <React.StrictMode>
    <ContextProvider>
      <AuthContextProvider>
        <InterestsContextProvider>
          <TasksContextProvider>
            <UsersContextProvider>
              <MentorContextProvider>
              <DashboardContextProvider>
                  <UserFeedbackContextProvider>
                    <App />
                  </UserFeedbackContextProvider>
                </DashboardContextProvider>
              </MentorContextProvider>
            </UsersContextProvider>
          </TasksContextProvider>
        </InterestsContextProvider>
      </AuthContextProvider>
    </ContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
