import React, { createContext, useCallback, useContext, useState } from "react";
import ApiTemplate from "../apis/api_template";
import FormData from "form-data";

const InterestsContext = createContext();

export const InterestsContextProvider = ({ children }) => {
  const [allInterests, setAllInterests] = useState(null);
  const [interestBuckets, setInterestBuckets] = useState(null);
  const [interestBucketTasks, setInterestBucketTasks] = useState(null);

  const getAllInterests = useCallback(async (token) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate("get", "/api/interest", {}, headers);
      if (response["success"] === true) {
        console.log(response["data"]);
        setAllInterests(response["data"]);
      } else {
        console.log(response["message"], response.message);
        alert(response["message"]);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  }, [
  ]);

  const getAllInterestBuckets = async (token) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate(
        "get",
        "/api/sub-buckets",
        {},
        headers
      );
      if (response["success"] === true) {
        console.log(response["data"]);
        setInterestBuckets(response["data"]);
      } else {
        console.log(response["message"], response.message);
        alert(response["message"]);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const getAllInterestBucketTasks = async (token, id) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate(
        "get",
        "/api/interest/" + id,
        {},
        headers
      );
      if (response["success"] === true) {
        console.log(response["data"]);
        const updatedBucketsTasks = response["data"].filter(item => item.mentor_id == null)
        setInterestBucketTasks(updatedBucketsTasks);
        console.log("shahsahshadhfkdsjflkdsjfkdsf", updatedBucketsTasks)
      } else {
        console.log(response["message"], response.message);
        alert(response["message"]);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const addInterest = async (token, image, name) => {
    try {
      const headers = {
        "Content-Type": "multipart/form-data",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      var data = new FormData();
      data.append("file", image);
      data.append("name", name);
      console.log(data);

      const response = await ApiTemplate(
        "post",
        "/api/interest",
        data,
        headers
      );
      if (response["success"] === true) {
        console.log(response);
        await getAllInterests(token);
        return true;
      } else {
        console.log(response["msg"], response.msg);
        alert(response["msg"]);
        return false;
      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;
    }
  };

  const addInterstBucket = async (token, name, id) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      let data = JSON.stringify({
        "interest_id": id,
        "name": name
      });

      const response = await ApiTemplate(
        "post",
        "/api/sub-bucket",
        data,
        headers
      );
      if (response["success"] === true) {
        console.log(response);
        await getAllInterests(token);
        return true;
      } else {
        console.log(response["msg"], response.msg);
        alert(response["msg"]);
        return false;
      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;
    }
  };

  const updateInterstBucket = async (token, data, subCategoryId) => {
    try {
      const headers = {
        "Content-Type": "multipart/form-data",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      // let newData = {
      //   name: data.get("name"),
      //   file: data.get("file"),
      //   "_method": "put",
      //   "interest_id": categoryId,
      // }
      console.log(",,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,");
      console.log(data.get("file"));
      console.log(",,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,");
      const response = await ApiTemplate(
        "post",
        "/api/sub-bucket/" + subCategoryId,
        data,
        headers
      );
      if (response["success"] === true) {
        console.log(response);
        await getAllInterestBucketTasks(token);
        return true;
      } else {
        console.log(response["msg"], response.msg);
        alert(response["msg"]);
        return false;
      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;
    }
  };

  const addSubBucketTask = async (token, image, name, time, id, subBucketId) => {
    try {
      const headers = {
        "Content-Type": "multipart/form-data",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      var data = new FormData();
      data.append("file", image);
      data.append("name", name);
      time && data.append("time", time);
      data.append("interest_id", id);
      data.append("sub_bucket_id", subBucketId);
      console.log(data);

      const response = await ApiTemplate(
        "post",
        "/api/sub-interest",
        data,
        headers
      );
      if (response["success"] === true) {
        console.log(response);
        await getAllInterestBucketTasks(token, subBucketId);
        return true;
      } else {
        console.log(response["msg"], response.msg);
        alert(response["msg"]);
        return false;
      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;
    }
  };

  const updateInterest = async (token, data, id) => {
    try {
      const headers = {
        "Content-Type": "multipart/form-data",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      console.log(".....................................");
      console.log(data.get("file"));
      console.log(".....................................");
      const response = await ApiTemplate(
        "post",
        "/api/interest/" + id,
        data,
        headers
      );
      if (response["success"] === true) {
        console.log(response["data"]);
        await getAllInterests(token);
        return true;
      } else {
        console.log(response["msg"], response.msg);
        alert(response["msg"]);
        return false;
      }
    } catch (error) {
      // console.error("Error during API call:", error.toString());
      // alert("An error occurred while fetching mentors.");
      return false;
    }
  };

  const updateSubBucketTask = async (token, data, id) => {
    try {
      const headers = {
        "Content-Type": "multipart/form-data",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate(
        "post",
        "/api/sub-interest/" + id,
        data,
        headers
      );
      if (response["success"] === true) {
        console.log(response["data"]);
        await getAllInterestBucketTasks(token, id);
        return true;
      } else {
        console.log(response["msg"], response.msg);
        alert(response["msg"]);
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const deleteEverything = async (token, type, id) => {
    try {
      const headers = {
        "Content-Type": "multipart/form-data",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      var data = new FormData();
      data.append("type", type);
      data.append("id", id);
      console.log(data);

      const response = await ApiTemplate(
        "post",
        "/api/delete",
        data,
        headers
      );
      if (response["success"] === true) {
        console.log(response);
        return true;
      } else {
        console.log(response["msg"], response.msg);
        alert(response["msg"]);
        return false;
      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;
    }
  };

  return (
    <InterestsContext.Provider
      value={{
        getAllInterests,
        allInterests,
        updateSubBucketTask,
        addInterest,
        addInterstBucket,
        addSubBucketTask,
        updateInterstBucket,
        updateInterest,
        getAllInterestBuckets,
        interestBuckets,
        interestBucketTasks,
        getAllInterestBucketTasks,
        deleteEverything,
        setInterestBucketTasks
      }}
    >
      {children}
    </InterestsContext.Provider>
  );
};

export const useInterestsContext = () => {
  const interestsContextValue = useContext(InterestsContext);
  return interestsContextValue;
};
