import React, { useState } from "react";
import { FaEdit } from "react-icons/fa";
import avatar3 from "./../data/avatar3.png";
import { employeeDataView1 } from "../data/dummy";
import { Header } from "../components";
import { useThemeContext } from "../contexts/ThemeContextProvider";

const EmployeeUpdate = () => {
  const { currentColor } = useThemeContext();
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="pt-24 pb-10 bg-gray-100 md:py-10 ">
      <div className="p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl">
        <Header
          category="User"
          title={`${employeeDataView1.FirstName}  ${employeeDataView1.LastName}`}
        />

        {/* Image */}
        <div className="relative w-full mb-4">
          {selectedImage ? (
            <div className="relative flex items-center justify-center gap-2">
              <img
                className="w-48 h-48 rounded-full"
                src={selectedImage}
                alt="employee"
              />

              {/* Edit Icon */}
              <div className="absolute bottom-0 right-0 flex items-center justify-center w-[90%]">
                <div
                  style={{ backgroundColor: currentColor }}
                  className="p-4 rounded-full cursor-pointer h-min w-min"
                  onClick={() => document.getElementById("fileInput").click()}
                >
                  <FaEdit className="text-xl text-white" />
                </div>
              </div>
            </div>
          ) : (
            <div className="relative flex items-center justify-center gap-2">
              <img
                className="w-48 h-48 rounded-full"
                src={avatar3}
                alt="employee"
              />
              {/* Edit Icon */}
              <div className="absolute bottom-0 right-0 flex items-center justify-center w-[90%]">
                <div
                  style={{ backgroundColor: currentColor }}
                  className="p-4 rounded-full cursor-pointer h-min w-min"
                  onClick={() => document.getElementById("fileInput").click()}
                >
                  <FaEdit className="text-xl text-white" />
                </div>
              </div>
            </div>
          )}

          {/* Hidden File Input */}
          <input
            id="fileInput"
            type="file"
            accept="image/*"
            className="hidden"
            onChange={handleImageChange}
          />
        </div>

        {/* update form */}
        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="p-1.5 w-full inline-block align-middle">
              <div className="w-full p-6 m-auto bg-white rounded-md md:max-w-md">
                <form className="flex flex-col items-center mt-6 ">
                  {/* first name */}
                  <div className="w-full mb-2">
                    <span className="text-black">First Name</span>
                    <input
                      type="text"
                      name="firstName"
                      className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                      defaultValue={employeeDataView1.FirstName}
                    />
                  </div>
                  {/* last name */}
                  <div className="w-full mb-2">
                    <span className="text-black">Last Name</span>
                    <input
                      type="text"
                      name="lastName"
                      className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                      defaultValue={employeeDataView1.LastName}
                    />
                  </div>
                  {/* Gender */}
                  <div className="w-full mb-2">
                    <span className="text-black">Gender</span>
                    <input
                      type="text"
                      name="gender"
                      className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                      defaultValue={employeeDataView1.Gender}
                    />
                  </div>
                  {/* Email Address */}
                  <div className="w-full mb-2">
                    <span className="text-black">Email Address</span>
                    <input
                      type="email"
                      name="email"
                      className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                      defaultValue={employeeDataView1.EmailAddress}
                    />
                  </div>
                  {/* Last Login Date */}
                  <div className="w-full mb-2">
                    <span className="text-black">Last Login Date</span>
                    <input
                      type="text"
                      name="lastLoginDate"
                      className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                      defaultValue={employeeDataView1.LastLoginDate}
                    />
                  </div>
                  {/* Referral Code */}
                  <div className="w-full mb-2">
                    <span className="text-black">Referral Code</span>
                    <input
                      type="text"
                      name="referralCode"
                      className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                      defaultValue={employeeDataView1.ReferralCode}
                    />
                  </div>
                  {/* Number of Successful Referrals */}
                  <div className="w-full mb-2">
                    <span className="text-black">
                      Number of Successful Referrals
                    </span>
                    <input
                      type="number"
                      name="numberOfSuccessfulReferrals"
                      className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                      defaultValue={
                        employeeDataView1.NumberOfSuccessfulReferrals
                      }
                    />
                  </div>
                  {/* Social Media Integration */}
                  <div className="w-full mb-2">
                    <span className="text-black">Social Media Integration</span>
                    <ul className="ml-5">
                      <li>
                        Twitter:{" "}
                        <input
                          type="text"
                          name="twitter"
                          className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                          defaultValue={
                            employeeDataView1.SocialMediaIntegration.Twitter
                          }
                        />
                      </li>
                      <li>
                        Facebook:{" "}
                        <input
                          type="text"
                          name="facebook"
                          className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                          defaultValue={
                            employeeDataView1.SocialMediaIntegration.Facebook
                          }
                        />
                      </li>
                      <li>
                        Instagram:{" "}
                        <input
                          type="text"
                          name="instagram"
                          className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                          defaultValue={
                            employeeDataView1.SocialMediaIntegration.Instagram
                          }
                        />
                      </li>
                    </ul>
                  </div>
                  {/* User Feedback */}
                  <div className="w-full mb-2">
                    <span className="text-black">User Feedback</span>
                    <textarea
                      name="userFeedback"
                      rows="4"
                      className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                      defaultValue={employeeDataView1.UserFeedback}
                    ></textarea>
                  </div>
                  {/* Password */}
                  <div className="w-full mb-2">
                    <span className="text-black">Password</span>
                    <input
                      type="password"
                      name="password"
                      className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                      defaultValue={employeeDataView1.Password}
                    />
                  </div>
                  {/* Date of Birth */}
                  <div className="w-full mb-2">
                    <span className="text-black">Date of Birth</span>
                    <input
                      type="date"
                      name="dateOfBirth"
                      className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                      defaultValue={employeeDataView1.DateOfBirth}
                    />
                  </div>
                  {/* Total Number of Tasks Created */}
                  <div className="w-full mb-2">
                    <span className="text-black">
                      Total Number of Tasks Created
                    </span>
                    <input
                      type="number"
                      name="totalTasksCreated"
                      className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                      defaultValue={employeeDataView1.TotalNumberOfTasksCreated}
                    />
                  </div>
                  {/* Total Number of Tasks Completed */}
                  <div className="w-full mb-2">
                    <span className="text-black">
                      Total Number of Tasks Completed
                    </span>
                    <input
                      type="number"
                      name="totalTasksCompleted"
                      className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                      defaultValue={
                        employeeDataView1.TotalNumberOfTasksCompleted
                      }
                    />
                  </div>
                  {/* Total Time Spent on Tasks */}
                  <div className="w-full mb-2">
                    <span className="text-black">
                      Total Time Spent on Tasks
                    </span>
                    <input
                      type="text"
                      name="totalTimeSpentOnTasks"
                      className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                      defaultValue={employeeDataView1.TotalTimeSpentOnTasks}
                    />
                  </div>
                  {/* Total Number of Tasks Overdue */}
                  <div className="w-full mb-2">
                    <span className="text-black">
                      Total Number of Tasks Overdue
                    </span>
                    <input
                      type="number"
                      name="totalTasksOverdue"
                      className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                      defaultValue={employeeDataView1.TotalNumberOfTasksOverdue}
                    />
                  </div>
                  {/* Total Number of Tasks with High/Medium/Low Priority */}
                  <div className="w-full mb-2">
                    <span className="text-black">
                      Total Number of Tasks with High/Medium/Low Priority
                    </span>
                    <ul className="ml-5">
                      <li>
                        High:{" "}
                        <input
                          type="number"
                          name="totalTasksHighPriority"
                          className="block w-1/3 px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                          defaultValue={
                            employeeDataView1[
                              "TotalNumberOfTasksWithHigh/Medium/LowPriority"
                            ].High
                          }
                        />
                      </li>
                      <li>
                        Medium:{" "}
                        <input
                          type="number"
                          name="totalTasksMediumPriority"
                          className="block w-1/3 px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                          defaultValue={
                            employeeDataView1[
                              "TotalNumberOfTasksWithHigh/Medium/LowPriority"
                            ].Medium
                          }
                        />
                      </li>
                      <li>
                        Low:{" "}
                        <input
                          type="number"
                          name="totalTasksLowPriority"
                          className="block w-1/3 px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                          defaultValue={
                            employeeDataView1[
                              "TotalNumberOfTasksWithHigh/Medium/LowPriority"
                            ].Low
                          }
                        />
                      </li>
                    </ul>
                  </div>
                  {/* Number of Times App Opened */}
                  <div className="w-full mb-2">
                    <span className="text-black">
                      Number of Times App Opened
                    </span>
                    <input
                      type="number"
                      name="numberOfTimesAppOpened"
                      className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                      defaultValue={employeeDataView1.NumberOfTimesAppOpened}
                    />
                  </div>
                  {/* Time Spent on App Per Session */}
                  <div className="w-full mb-2">
                    <span className="text-black">
                      Time Spent on App Per Session
                    </span>
                    <input
                      type="text"
                      name="timeSpentOnAppPerSession"
                      className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                      defaultValue={employeeDataView1.TimeSpentOnAppPerSession}
                    />
                  </div>
                  {/* User Engagement Metrics */}
                  <div className="w-full mb-2">
                    <span className="text-black">User Engagement Metrics</span>
                    <ul className="ml-5">
                      <li>
                        Frequency of App Use:{" "}
                        <input
                          type="text"
                          name="frequencyOfAppUse"
                          className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                          defaultValue={
                            employeeDataView1.UserEngagementMetrics
                              .FrequencyOfAppUse
                          }
                        />
                      </li>
                      <li>
                        Active Users:{" "}
                        <input
                          type="number"
                          name="activeUsers"
                          className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                          defaultValue={
                            employeeDataView1.UserEngagementMetrics.ActiveUsers
                          }
                        />
                      </li>
                    </ul>
                  </div>
                  {/* Address */}
                  <div className="w-full mb-2">
                    <span className="text-black">Address</span>
                    <input
                      type="text"
                      name="address"
                      className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                      defaultValue={employeeDataView1.Address}
                    />
                  </div>
                  {/* City */}
                  <div className="w-full mb-2">
                    <span className="text-black">City</span>
                    <input
                      type="text"
                      name="city"
                      className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                      defaultValue={employeeDataView1.City}
                    />
                  </div>
                  {/* Postal Code */}
                  <div className="w-full mb-2">
                    <span className="text-black">Postal Code</span>
                    <input
                      type="text"
                      name="postalCode"
                      className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                      defaultValue={employeeDataView1.PostalCode}
                    />
                  </div>
                  {/* Country */}
                  <div className="w-full mb-2">
                    <span className="text-black">Country</span>
                    <input
                      type="text"
                      name="country"
                      className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                      defaultValue={employeeDataView1.Country}
                    />
                  </div>
                  {/* Location */}
                  <div className="w-full mb-2">
                    <span className="text-black">Location</span>
                    <ul className="ml-5">
                      <li>
                        Latitude:{" "}
                        <input
                          type="number"
                          name="latitude"
                          className="block w-1/3 px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                          defaultValue={employeeDataView1.Location.Latitude}
                        />
                      </li>
                      <li>
                        Longitude:{" "}
                        <input
                          type="number"
                          name="longitude"
                          className="block w-1/3 px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                          defaultValue={employeeDataView1.Location.Longitude}
                        />
                      </li>
                    </ul>
                  </div>
                  {/* Contact Information */}
                  <div className="w-full mb-2">
                    <span className="text-black">Contact Information</span>
                    <input
                      type="tel"
                      name="phoneNumber"
                      className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                      defaultValue={
                        employeeDataView1.ContactInformation.PhoneNumber
                      }
                    />
                  </div>
                  {/* Preferences */}
                  <div className="w-full mb-2">
                    <span className="text-black">Preferences</span>
                    <ul className="ml-5">
                      <li>
                        Language:{" "}
                        <input
                          type="text"
                          name="language"
                          className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                          defaultValue={employeeDataView1.Preferences.Language}
                        />
                      </li>
                      <li>
                        Theme:{" "}
                        <input
                          type="text"
                          name="theme"
                          className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                          defaultValue={employeeDataView1.Preferences.Theme}
                        />
                      </li>
                      <li>
                        Notification Preferences:{" "}
                        <ul className="ml-5">
                          <li className="flex gap-x-3">
                            <input
                              type="checkbox"
                              name="emailNotifications"
                              defaultChecked={
                                employeeDataView1.Preferences
                                  .NotificationPreferences.Email
                              }
                            />
                            <span>Email</span>
                          </li>
                          <li className="flex gap-x-3">
                            <input
                              type="checkbox"
                              name="pushNotifications"
                              defaultChecked={
                                employeeDataView1.Preferences
                                  .NotificationPreferences.Push
                              }
                            />
                            <span>Push</span>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  {/* Subscription/Payment Information */}
                  <div className="w-full mb-2">
                    <span className="text-black">
                      Subscription/Payment Information
                    </span>
                    <ul className="ml-5">
                      <li>
                        Subscription Type:{" "}
                        <input
                          type="text"
                          name="subscriptionType"
                          className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                          defaultValue={
                            employeeDataView1["Subscription/PaymentInformation"]
                              .SubscriptionType
                          }
                        />
                      </li>
                      <li>
                        Subscription Expiry Date:{" "}
                        <input
                          type="text"
                          name="subscriptionExpiryDate"
                          className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                          defaultValue={
                            employeeDataView1["Subscription/PaymentInformation"]
                              .SubscriptionExpiryDate
                          }
                        />
                      </li>
                      <li>
                        Payment Method:{" "}
                        <input
                          type="text"
                          name="paymentMethod"
                          className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                          defaultValue={
                            employeeDataView1["Subscription/PaymentInformation"]
                              .PaymentMethod
                          }
                        />
                      </li>
                    </ul>
                  </div>
                  {/* User Activity */}
                  <div className="w-full mb-2">
                    <span className="text-black">User Activity</span>
                    <ul className="ml-5">
                      {/* Tasks Completed */}
                      <li>
                        <span className="text-black">Tasks Completed</span>
                        <ul>
                          {employeeDataView1.UserActivity.TasksCompleted.map(
                            (task) => (
                              <li key={task.TaskID}>
                                Task ID: {task.TaskID}, Task Name:{" "}
                                {task.TaskName}, Task Priority:{" "}
                                {task.TaskPriority}, Task Completion Date:{" "}
                                {task.TaskCompletionDate}
                              </li>
                            )
                          )}
                        </ul>
                      </li>
                      {/* Interactions */}
                      <li>
                        <span className="text-black">Interactions</span>
                        <ul>
                          <li>
                            Messages Sent:{" "}
                            <input
                              type="number"
                              name="messagesSent"
                              className="block w-1/3 px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                              defaultValue={
                                employeeDataView1.UserActivity.Interactions
                                  .MessagesSent
                              }
                            />
                          </li>
                          <li>
                            Likes Given:{" "}
                            <input
                              type="number"
                              name="likesGiven"
                              className="block w-1/3 px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                              defaultValue={
                                employeeDataView1.UserActivity.Interactions
                                  .LikesGiven
                              }
                            />
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  {/* Height */}
                  <div className="w-full mb-2">
                    <span className="text-black">Height</span>
                    <input
                      type="text"
                      name="height"
                      className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                      defaultValue={employeeDataView1.Height}
                    />
                  </div>
                  {/* Weight */}
                  <div className="w-full mb-2">
                    <span className="text-black">Weight</span>
                    <input
                      type="text"
                      name="weight"
                      className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                      defaultValue={employeeDataView1.Weight}
                    />
                  </div>
                  {/* Body Mass Index (BMI) */}
                  <div className="w-full mb-2">
                    <span className="text-black">Body Mass Index (BMI)</span>
                    <input
                      type="text"
                      name="bmi"
                      className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                      defaultValue={employeeDataView1["BodyMassIndex(BMI)"]}
                    />
                  </div>
                  {/* Body Fat Percentage */}
                  <div className="w-full mb-2">
                    <span className="text-black">Body Fat Percentage</span>
                    <input
                      type="text"
                      name="bodyFatPercentage"
                      className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                      defaultValue={employeeDataView1.BodyFatPercentage}
                    />
                  </div>
                  {/* Waist Circumference */}
                  <div className="w-full mb-2">
                    <span className="text-black">Waist Circumference</span>
                    <input
                      type="text"
                      name="waistCircumference"
                      className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                      defaultValue={employeeDataView1.WaistCircumference}
                    />
                  </div>
                  {/* Germin Connected */}
                  <div className="flex w-full mb-2 gap-x-3">
                    <input
                      type="checkbox"
                      name="germinConnected"
                      defaultChecked={employeeDataView1.GerminConnected}
                    />
                    <span className="text-black">Germin Connected</span>
                  </div>
                  {/* AppleHealth Connected */}
                  <div className="flex w-full mb-2 gap-x-3">
                    <input
                      type="checkbox"
                      name="appleHealthConnected"
                      defaultChecked={employeeDataView1.AppleHealthConnected}
                    />
                    <span className="text-black">AppleHealth Connected</span>
                  </div>
                  {/* submit button */}
                  <div className="mt-8 ">
                    <button
                      style={{ backgroundColor: currentColor }}
                      type="submit"
                      className="h-10 px-10 text-white transition-colors duration-150 rounded-lg focus:shadow-outline"
                    >
                      Update User
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeUpdate;
