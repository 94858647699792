import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Navbar, Footer, Sidebar, ThemeSettings } from "./components";
import {
  Ecommerce,
  Orders,
  Calendar,
  Employees,
  Stacked,
  Pyramid,
  Customers,
  Kanban,
  Line,
  Area,
  Bar,
  Pie,
  Financial,
  ColorPicker,
  ColorMapping,
  Editor,
} from "./pages";
import "./App.css";

import { useThemeContext } from "./contexts/ThemeContextProvider";
import EmployeeView from "./pages/EmployeeView";
import EmployeeUpdate from "./pages/EmployeeUpdate";
import Interests from "./pages/Interests";
import InterestCategoryView from "./pages/InterestCategoryView";
import InterestSubCategory from "./pages/InterestSubCategory";
import InterestCategoryUpdate from "./pages/InterestCategoryUpdate";
import InterestSubCategoryView from "./pages/InterestSubCategoryView";
import InterestSubCategoryUpdate from "./pages/InterestSubCategoryUpdate";
import InterestSubCategoryAddTasks from "./pages/InterestSubCategoryAddTasks";
import InterestSubCategoryUpdateTask from "./pages/InterestSubCategoryUpdateTask";
import InterestCategoryAdd from "./pages/InterestCategoryAdd";
import InterestSubCategoryAdd from "./pages/InterestSubCategoryAdd";
import Mentors from "./pages/Mentors";
import MentorAdd from "./pages/MentorsAdd";
import MentorsView from "./pages/MentorsView";
import MentorsUpdate from "./pages/MentorsUpdate";
import MentorsTasks from "./pages/MentorsTasks";
import MentorsTasksAdd from "./pages/MentorsTasksAdd";
import MentorsTasksView from "./pages/MentorsTaskView";
import MentorsTasksUpdate from "./pages/MentorsTaskUpdate";
import Tasks from "./pages/Tasks";
import TasksUpdate from "./pages/TasksUpdate"
import TasksAdd from "./pages/TasksAdd";
import Login from "./pages/Login";
import { useAuthContext } from "./contexts/AuthContextProvider";
import { useInterestsContext } from "./contexts/InterestsContextProvider";
import { useUsersContext } from "./contexts/UsersContextProvider";
import { useMentorContext } from "./contexts/MentorContextProvider";
import { useDashboardContext } from "./contexts/DashboardContextProvider";
import EmployeeInterest from "./pages/EmployeeInterest";
import EmployeeChart from "./pages/EmployeeCharts";
import InterestSubCategoryTasks from "./pages/InterestSubCategoryTasks";
import UserFeedback from "./pages/UserFeedback";

const App = () => {
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    themeSettings,
  } = useThemeContext();
  // const { isLogin } = useAuthContext();
  const { isLogin, checkIsLogin, currentUser } = useAuthContext();

  const { getAllInterests } = useInterestsContext();
  const { getAllMentors } = useMentorContext();
  const { getAllDashboard } = useDashboardContext();
  const { getAllUsers } = useUsersContext();

  useEffect(() => {
    const currentThemeColor = localStorage.getItem("colorMode");
    const currentThemeMode = localStorage.getItem("themeMode");
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, [
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    themeSettings,
  ]);
  useEffect(() => {
    
    checkIsLogin();


    return () => {
      checkIsLogin();

    }
  }, [checkIsLogin])

  useEffect(() => {

    if (isLogin) {
      getAllInterests(currentUser["token"]);
      getAllUsers(currentUser["token"]);
      getAllMentors(currentUser["token"]);
      getAllDashboard(currentUser["token"]);
    }
  }, [getAllInterests, isLogin, currentUser, getAllUsers, getAllMentors, getAllDashboard]);
  return (
    <div className={currentMode === "Dark" ? "dark" : ""}>
      <BrowserRouter>
        {isLogin ? (
          <div className="relative flex dark:bg-main-dark-bg">
            {activeMenu ? (
              <div className="fixed bg-white w-72 sidebar dark:bg-secondary-dark-bg ">
                <Sidebar />
              </div>
            ) : (
              <div className="w-0 dark:bg-secondary-dark-bg">
                <Sidebar />
              </div>
            )}
            <div
              className={
                activeMenu
                  ? "dark:bg-main-dark-bg  bg-main-bg min-h-screen md:pl-72 w-full  "
                  : "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 "
              }
            >
              <div className="fixed w-full md:static bg-main-bg dark:bg-main-dark-bg navbar ">
                <Navbar />
              </div>
              <div>
                {themeSettings && <ThemeSettings />}

                <Routes>
                  {/* dashboard  */}
                  <Route path="/" element={<Ecommerce />} />
                  <Route path="/dashboard" element={<Ecommerce />} />

                  {/* user feedback */}
                  <Route path="/user_feedbacks" element={<UserFeedback />} />

                  {/* users */}
                  <Route path="/users" element={<Employees />} />

                  <Route
                    path="/users/:id/bucket"
                    element={<EmployeeInterest />}
                  />
                  <Route path="/users/:id/charts" element={<EmployeeChart />} />
                  <Route path="/users/:id/view" element={<EmployeeView />} />
                  <Route path="/users/:id/edit" element={<EmployeeUpdate />} />
                  <Route path="/users/:id/delete" element={<Customers />} />

                  {/* tasks */}
                  <Route path="/tasks" element={<Tasks />} />

                  <Route path="/tasks/add" element={<TasksAdd />}
                  />

                  <Route path="/tasks/:id/edit" element={<TasksUpdate />} />


                  {/* interests */}
                  <Route path="/bucket" element={<Interests />} />
                  <Route
                    path="/bucket/:id"
                    element={<InterestSubCategory />}
                  />
                  <Route
                    path="/bucket/:id/sub-buckets/:subBucketId"
                    element={<InterestSubCategoryTasks />}
                  />
                  <Route
                    path="/bucket/:id/sub-buckets/:subBucketId/add-task"
                    element={<InterestSubCategoryAddTasks />}
                  />`
                  <Route
                    path="/bucket/:id/sub-buckets/:subBucketId/task/:taskId/update"
                    element={<InterestSubCategoryUpdateTask />}
                  />`
                  <Route
                    path="/bucket/category/add"
                    element={<InterestCategoryAdd />}
                  />
                  <Route
                    path="/bucket/category/:id/view"
                    element={<InterestCategoryView />}
                  />
                  <Route
                    path="/bucket/category/:id/edit"
                    element={<InterestCategoryUpdate />}
                  />
                  <Route
                    path="/bucket/:categoryId/subcategory/add"
                    element={<InterestSubCategoryAdd />}
                  />
                  <Route
                    path="/bucket/:categoryId/subcategory/:subCategoryId/view"
                    element={<InterestSubCategoryView />}
                  />


                  <Route
                    path="/bucket/:categoryId/subcategory/:subCategoryId/edit"
                    element={<InterestSubCategoryUpdate />}
                  />

                  {/* mentors */}
                  <Route path="/mentors" element={<Mentors />} />
                  <Route path="/mentors/add" element={<MentorAdd />} />
                  <Route
                    path="/mentors/:mentorId/view"
                    element={<MentorsView />}
                  />
                  <Route
                    path="/mentors/:mentorId/edit"
                    element={<MentorsUpdate />}
                  />
                  <Route
                    path="/mentors/:mentorId/tasks"
                    element={<MentorsTasks />}
                  />
                  <Route
                    path="/mentors/:mentorId/tasks/add"
                    element={<MentorsTasksAdd />}
                  />
                  <Route
                    path="/mentors/:mentorId/tasks/:taskId/view"
                    element={<MentorsTasksView />}
                  />
                  <Route
                    path="/mentors/:mentorId/tasks/:taskId/edit"
                    element={<MentorsTasksUpdate />}
                  />

                  {/* pages  */}
                  <Route path="/orders" element={<Orders />} />
                  <Route path="/employees" element={<Employees />} />
                  <Route path="/customers" element={<Customers />} />

                  {/* apps  */}
                  <Route path="/kanban" element={<Kanban />} />
                  <Route path="/editor" element={<Editor />} />
                  <Route path="/calendar" element={<Calendar />} />
                  <Route path="/color-picker" element={<ColorPicker />} />

                  {/* charts  */}
                  <Route path="/line" element={<Line />} />
                  <Route path="/area" element={<Area />} />
                  <Route path="/bar" element={<Bar />} />
                  <Route path="/pie" element={<Pie />} />
                  <Route path="/financial" element={<Financial />} />
                  <Route path="/color-mapping" element={<ColorMapping />} />
                  <Route path="/pyramid" element={<Pyramid />} />
                  <Route path="/stacked" element={<Stacked />} />
                </Routes>
              </div>
              <Footer />
            </div>
          </div>
        ) : (
          <Routes>
            <Route path="/*" element={<Login />} />
            <Route path="/" element={<Login />} />
          </Routes>
        )}
      </BrowserRouter>
    </div>
  );
};

export default App;
