import React, { useCallback, useEffect, useState } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
} from "@syncfusion/ej2-react-grids";

import { employeeInterestGrid } from "../data/dummy";
import { Header } from "../components";
import { useUsersContext } from "../contexts/UsersContextProvider";
import { useAuthContext } from "../contexts/AuthContextProvider";
import { useParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { useThemeContext } from "../contexts/ThemeContextProvider";

const EmployeeInterest = () => {
  const { id } = useParams();

  const toolbarOptions = ["Search"];
  const { userInterest, getUserInterest } = useUsersContext();
  const { currentUser } = useAuthContext();
  const editing = { allowDeleting: true, allowEditing: true };
  const [loading, setLoading] = useState(false);
  const { currentColor } = useThemeContext();

  console.log("hereeeeeeeeeeeeeee", userInterest);
  const getUserInterestFunc = useCallback(async () => {
    await getUserInterest(currentUser["token"], id);
  }, [id, currentUser, getUserInterest]);

  useEffect(() => {
    getUserInterestFunc();
  }, [getUserInterestFunc]);

  const convertValueToString = (value) => {
    if (Array.isArray(value)) {
      // If value is an array, convert it to "value1-value2-value3"
      return value.join("-");
    } else if (
      typeof value === "object" &&
      value !== null &&
      !Array.isArray(value)
    ) {
      // If value is an object (excluding arrays and null), convert it to "key1:value1-key2:value2"
      return Object.entries(value)
        .map(([key, val]) => `${key}:${val}`)
        .join("-");
    }
    // Otherwise, convert to string
    return String(value);
  };
  const convertToCSV = (user) => {
    console.log(user);

    const header = Object.keys(user[0]).join(",") + "\n";
    const body = user
      .map((user) =>
        Object.values({
          ...user,
        })
          .map(convertValueToString)
          .join(",")
      )
      .join("\n");

    return header + body;
  };

  // function calculateTimeDifference(time1, time2) {
  //   const [hours1, minutes1] = time1.split(":").map(Number);
  //   const [hours2, minutes2] = time2.split(":").map(Number);

  //   const totalMinutes1 = hours1 * 60 + minutes1;
  //   const totalMinutes2 = hours2 * 60 + minutes2;

  //   const differenceInMinutes = totalMinutes2 - totalMinutes1;

  //   const hours = Math.floor(differenceInMinutes / 60);
  //   const remainingMinutes = differenceInMinutes % 60;
  //   const seconds = Math.floor(
  //     (differenceInMinutes - Math.floor(differenceInMinutes)) * 60
  //   );

  //   return `${hours}:${remainingMinutes}:${seconds}`;
  // }
  function convertMinutesToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    const hoursStr = String(hours).padStart(2, "0");
    const minutesStr = String(remainingMinutes).padStart(2, "0");

    return `${hoursStr}:${minutesStr}:00`;
  }

  const downloadCSV = () => {
    setLoading(true);
    const data = userInterest.map((user) => {
      let date = user["task_date"]
        ? user["task_date"].split(" ")[0].split("-").join("/")
        : user["updated_at"].split("T")[0].split("-").join("/");

      return {
        Date: date ? date : "_",
        Day: date ? date.split("/")[2] : "_",
        Month: date ? date.split("/")[1] : "_",
        Year: date ? date.split("/")[0] : "_",
        "Unique Id Number": user["task_id"]
          ? user["task_id"] + 1000
          : user["id"],
        "User Id": user["user_id"],
        Name: `"${user["task"]}"`, 
        Bucket: `"${user["bucket"]}"`, 
        "Sub Bucket": `"${user["sub_bucket"]}"`,
        "Activity Id": user["id"] ? user["id"] : user["task_id"],
        Points: user["importance"],
        "Points Collected":
          user["status"] === "complete" ? user["importance"] : "0",
        // "How Often":user["how_often"],
        "Estimates Start Time": user["best_time"],
        "Estimates End Time": user["estimated_end_time"],
        "Estimated Time Set":
          user["how_long"] !== "_"
            ? convertMinutesToHoursAndMinutes(user["how_long"])
            : "_",
        "Actual Time Started": user["start_time"].split(" ")[1]
          ? user["start_time"].split(" ")[1]
          : user["start_time"].split(" ")[0],
        "Actual Time Ended": user["end_time"].split(" ")[1]
          ? user["end_time"].split(" ")[1]
          : user["end_time"].split(" ")[0],
        "Actual Time Spent": user["actual_time_spend"]
          ? `"${
              user["actual_time_spend"].split(" ")[1]
                ? user["actual_time_spend"].split(" ")[1]
                : user["actual_time_spend"].split(" ")[0]
            }"`
          : "_",
        // "Days Repeat": user["days_repeat"],
        Status: user["status"],
        Location: `"${user["location"]}"`,
        House: `"${user["house"]}"`,
        Street: `"${user["street"]}"`,
        Area: `"${user["area"]}"`,
        City: `"${user["city"]}"`,
        "Postal Code": `"${user["post_code"]}"`,
        Country: `"${user["country"]}"`,
      };
    });
    const csvContent = "data:text/csv;charset=utf-8," + convertToCSV(data);
    const encodedUri = encodeURI(csvContent);

    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "user_tasks.csv");
    link.setAttribute("target", "_blank"); // Add target attribute for opening in a new tab
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setLoading(false);
  };

  if (!id) {
    return (
      <div className='p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl'>
        <h5 className='font-bold text-center text-md'>User Id is Null</h5>
      </div>
    );
  }

  return (
    <div className='pt-24 pb-10 bg-gray-100 md:py-10 '>
      {userInterest ? (
        <div className='p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl'>
          <Header category='Page' title='User Tasks' />
          {/* export to csv button */}
          <div className='flex items-center justify-end w-full my-3 mt-5'>
            <button
              style={{ backgroundColor: currentColor }}
              onClick={downloadCSV}
              disabled={loading}
              className='h-10 px-10 text-white transition-colors duration-150 rounded-lg focus:shadow-outline'>
              {loading ? (
                <ThreeDots color='#fff' height={20} width={40} />
              ) : (
                "Export To CSV"
              )}
            </button>
          </div>
          <GridComponent
            dataSource={userInterest}
            width='auto'
            allowPaging
            allowSorting
            pageSettings={{ pageCount: 5 }}
            editSettings={editing}
            toolbar={toolbarOptions}>
            <ColumnsDirective>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              {employeeInterestGrid.map((item, index) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <ColumnDirective key={index} {...item} />
              ))}
            </ColumnsDirective>
            <Inject services={[Search, Page]} />
          </GridComponent>
        </div>
      ) : (
        <div className='p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl'>
          <h5 className='font-bold text-center text-md'>Loading ....</h5>
        </div>
      )}
    </div>
  );
};
export default EmployeeInterest;
