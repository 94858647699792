import React, { createContext, useCallback, useContext, useState } from "react";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [isLogin, setIsLogin] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const checkIsLogin = useCallback(async () => {
    const user = await JSON.parse(await localStorage.getItem("user"));
    if (user) {
      console.log(user);
      setCurrentUser(user);
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [setIsLogin]);

  const getCurrentUser = async () => {
    const user = await JSON.parse(await localStorage.getItem("user"));
    if (user) {
      console.log(user)
      setCurrentUser(user);
    } else {
      setCurrentUser(null);
    }
  };

  const logOut = async () => {
    const user = await JSON.parse(await localStorage.getItem("user"));
    if (user) {
      await localStorage.removeItem("user")
      setIsLogin(false);
    } else {
      setIsLogin(false);
    }
    window.location.href = "/";
  };

  const handleIsLoginUpdate = (val) => {
    setIsLogin(val);
  };

  return (
    <AuthContext.Provider value={{ isLogin, checkIsLogin, handleIsLoginUpdate, getCurrentUser, logOut, currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  // Access the value from the context, not directly destructure from useContext
  const authContextValue = useContext(AuthContext);
  return authContextValue;
};
