// DashboardContext.js

import React, { createContext, useCallback, useContext, useState } from "react";
import ApiTemplate from "../apis/api_template";

const DashboardContext = createContext();

export const DashboardContextProvider = ({ children }) => {
  const [dashboardData, setDashboardData] = useState(null);

  const getAllDashboard = useCallback(
    async (token) => {
      try {
        const headers = {
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": document.head
            .querySelector('meta[name="csrf-token"]')
            ?.getAttribute("content"),
          Authorization: `Bearer ${token}`,
        };

        const response = await ApiTemplate(
          "get",
          "/api/dashboard",
          {},
          headers
        );
        if (response["success"] === true) {
          console.log(response);
          setDashboardData(response);
        } else {
          console.log(response["message"], response.message);
          alert(response["message"]);
        }
      } catch (error) {
        console.error("Error during API call:", error);
      }
    },
    [setDashboardData]
  );

  const usersRecentCompletedTasks = useCallback(async (token) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate(
        "get",
        "/api/recent-user-complete-tasks",
        {},
        headers
      );

      if (response) {
        const blob = new Blob([response], { type: "text/csv" });
        const downloadUrl = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = "completed-tasks.csv";
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        window.URL.revokeObjectURL(downloadUrl);
      } else {
        alert("An error occurred while trying to download the CSV file.");
      }
    } catch (error) {
      console.error("Error during API call:", error);
      alert("An error occurred while trying to download the CSV file.");
    }
  }, []);

  return (
    <DashboardContext.Provider
      value={{
        dashboardData,
        getAllDashboard,
        usersRecentCompletedTasks,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => {
  const dashboardContextValue = useContext(DashboardContext);
  return dashboardContextValue;
};
