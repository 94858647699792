import React, { useCallback, useEffect, useState } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
} from "@syncfusion/ej2-react-grids";
import { mentorsGrid } from "../data/dummy";
import { Header } from "../components";
import { Link, useLocation } from "react-router-dom";
import { useThemeContext } from "../contexts/ThemeContextProvider";
import { useMentorContext } from "../contexts/MentorContextProvider";
import { useAuthContext } from "../contexts/AuthContextProvider";
import { useInterestsContext } from "../contexts/InterestsContextProvider";
import DeleteConfirmationModal from "../components/DeleteConfirmationModel";

const Mentors = () => {
  const { currentColor } = useThemeContext();
  const toolbarOptions = ["Search"];

  const { allMentors, getAllMentors } = useMentorContext();
  const { checkIsLogin, isLogin, currentUser } = useAuthContext();

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteInterestId, setDeleteInterestId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [back, setBack] = useState(-1);
  const { deleteEverything } = useInterestsContext();

  useEffect(() => {
    localStorage.removeItem("currentPage");
  }, []);

  useEffect(() => {
    checkIsLogin();
  }, [checkIsLogin]);

  const getMentors = useCallback(async () => {
    if (isLogin && currentUser) {
      await getAllMentors(currentUser["token"]);
    }
  }, [getAllMentors, isLogin, currentUser]);

  useEffect(() => {
    getMentors();
  }, [getMentors, isLogin, currentUser]);

  const editing = { allowDeleting: true, allowEditing: true };
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const deleteId = urlParams.get("delete");
    if (deleteId) {
      console.log("Delete ID:", deleteId);
      setDeleteInterestId(deleteId);
      setBack((prevBack) => prevBack - 1);
      setShowDeleteConfirmation(true);
    } else {
      console.log("No delete ID found.");
    }
  }, [location.search, location]);

  const handleDelete = async (id) => {
    try {
      setDeleteLoading(true);
      // Call the API to delete the sub-bucket
      const result = await deleteEverything(currentUser["token"], "Mentor", id);

      if (!result) {
        throw new Error("Data not Deleted");
      }
      // Refresh the data after successful deletion
      await getAllMentors(currentUser["token"]);
    } catch (error) {
      console.error("Error during delete:", error);
    } finally {
      // Close the delete confirmation modal
      setShowDeleteConfirmation(false);
      setDeleteInterestId(null);
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete("delete");
      const newUrl = `${window.location.pathname}`;
      window.history.replaceState({}, "", newUrl);
      setDeleteLoading(false);
    }
  };

  return (
    <div className='pt-24 pb-10 bg-gray-100 md:py-2 '>
      {allMentors ? (
        <div className='p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl'>
          <Header category='Page' title='Mentors' back={back} />
          <div className='flex items-center justify-end w-full my-3 mt-5'>
            {/* submit button */}
            <Link to='/mentors/add'>
              <button
                style={{ backgroundColor: currentColor }}
                type='submit'
                className='h-10 px-10 text-white transition-colors duration-150 rounded-lg focus:shadow-outline'>
                Add Mentor
              </button>
            </Link>
          </div>
          <GridComponent
            dataSource={allMentors}
            width='auto'
            allowPaging
            allowSorting
            pageSettings={{ pageCount: 5 }}
            editSettings={editing}
            toolbar={toolbarOptions}>
            <ColumnsDirective>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              {mentorsGrid.map((item, index) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <ColumnDirective key={index} {...item} />
              ))}
            </ColumnsDirective>
            <Inject services={[Search, Page]} />
          </GridComponent>

          {/* Delete Confirmation Modal */}
          <DeleteConfirmationModal
            isOpen={showDeleteConfirmation}
            loading={deleteLoading}
            onClose={() => {
              setShowDeleteConfirmation(false);
              setDeleteInterestId(null);
              const urlParams = new URLSearchParams(window.location.search);
              urlParams.delete("delete");
              const newUrl = `${window.location.pathname}`;
              window.history.replaceState({}, "", newUrl);
            }}
            onDelete={() => handleDelete(deleteInterestId)} // Assuming you want to delete the current interest
          />
        </div>
      ) : (
        <div className='p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl'>
          <h5 className='text-center font-bold text-md'>Loading ....</h5>
        </div>
      )}
    </div>
  );
};
export default Mentors;
