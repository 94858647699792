import React, { useCallback, useEffect, useState } from "react";
import { FaCheckCircle, FaEdit } from "react-icons/fa";
import { Header } from "../components";
import { useThemeContext } from "../contexts/ThemeContextProvider";
import { useNavigate, useParams } from "react-router-dom";
import { useInterestsContext } from "../contexts/InterestsContextProvider";
import { useAuthContext } from "../contexts/AuthContextProvider";
import { baseUrl } from "../apis/base_url";
import { ThreeDots } from "react-loader-spinner";

const InterestCategoryUpdate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [interestData, setInterestData] = useState(null);
  const { allInterests, getAllInterests, updateInterest } =
    useInterestsContext();
  const { currentColor } = useThemeContext();
  const [selectedImage, setSelectedImage] = useState(null);
  const { checkIsLogin, isLogin, currentUser } = useAuthContext();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  useEffect(() => {
    checkIsLogin();
  }, [checkIsLogin]);

  const getInterests = useCallback(async () => {
    if (isLogin && currentUser) {
      await getAllInterests(currentUser["token"]);
    }
  }, [getAllInterests, isLogin, currentUser]);

  const getInterest = useCallback(async () => {
    const interest = allInterests.find(
      (item) => item["id"].toString() === id.toString()
    );
    setInterestData(interest);
  }, [allInterests, id]);

  useEffect(() => getInterests(), [getInterests]);
  useEffect(() => getInterest(), [getInterest]);

  useEffect(() => {
    setTimeout(() => {
      setErrorMessage(null);
    }, 5000);
  }, [errorMessage]);

  const onInterestDataChange = useCallback(async () => {
    if (interestData) {
      setName(interestData["name"]);
    }
  }, [interestData]);

  useEffect(() => {
    onInterestDataChange();
  }, [onInterestDataChange]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    var data = new FormData();

    if (selectedImage == null && name.trim() !== "") {
      data.append("name", name);
    } else if (selectedImage !== null && name.trim() === "") {
      const currentDate = new Date();
      const timestamp = currentDate.getTime();
      const imageFilename = `mentor_image_${timestamp}.png`;

      // Convert base64 image data to a Blob
      const imageBlob = await fetch(selectedImage).then((res) => res.blob());

      // Create a File object from the Blob with the generated filename
      const imageFile = new File([imageBlob], imageFilename, {
        type: "image/png",
      });

      data.append("file", imageFile);
    } else if (selectedImage !== null && name.trim() !== "") {
      const currentDate = new Date();
      const timestamp = currentDate.getTime();
      const imageFilename = `mentor_image_${timestamp}.png`;

      // Convert base64 image data to a Blob
      const imageBlob = await fetch(selectedImage).then((res) => res.blob());

      // Create a File object from the Blob with the generated filename
      const imageFile = new File([imageBlob], imageFilename, {
        type: "image/png",
      });
      data.append("name", name);
      data.append("file", imageFile);
    } else {
      console.log("Please change an image or change a name.");
      setLoading(false);
      setErrorMessage("Please change an image and provide a name.");
      return false;
    }
    data.append("_method", "put");

    const response = await updateInterest(currentUser["token"], data, id);
    console.log("update mentor", response);
    if (response) {
      setSuccessMessage("Interest updated successfully!");
      setShowSuccessModal(true);
      setTimeout(() => {
        setShowSuccessModal(false);
        navigate(-1);
      }, 2000);
    } else {
      setLoading(false);
      setErrorMessage("Got an error! Please try again.");
      // Add this line
    }
    setLoading(false);
  };

  return (
    <div className='pt-24 pb-10 bg-gray-100 md:py-10 '>
      {interestData ? (
        <div className='p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl'>
          <Header category='Page' title='Interests' />

          {/* Image */}
          <div className='relative w-full mb-4'>
            {selectedImage ? (
              <div className='relative flex items-center justify-center gap-2'>
                <img
                  className='object-cover w-48 h-48 rounded-full'
                  src={selectedImage}
                  alt='employee'
                />

                {/* Edit Icon */}
                <div className='absolute bottom-0 right-0 flex items-center justify-center w-[90%]'>
                  <div
                    style={{ backgroundColor: currentColor }}
                    className='p-4 rounded-full cursor-pointer h-min w-min'
                    onClick={() =>
                      document.getElementById("fileInput").click()
                    }>
                    <FaEdit className='text-xl text-white' />
                  </div>
                </div>
              </div>
            ) : (
              <div className='relative flex items-center justify-center gap-2'>
                <img
                  className='object-cover w-48 h-48 rounded-full'
                  src={baseUrl + "/" + interestData["icon"]}
                  alt='employee'
                />
                {/* Edit Icon */}
                <div className='absolute bottom-0 right-0 flex items-center justify-center w-[90%]'>
                  <div
                    style={{ backgroundColor: currentColor }}
                    className='p-4 rounded-full cursor-pointer h-min w-min'
                    onClick={() =>
                      document.getElementById("fileInput").click()
                    }>
                    <FaEdit className='text-xl text-white' />
                  </div>
                </div>
              </div>
            )}

            {/* Hidden File Input */}
            <input
              id='fileInput'
              type='file'
              accept='image/*'
              className='hidden'
              onChange={handleImageChange}
            />
          </div>

          {/* update form */}
          <div className='flex flex-col'>
            <div className=''>
              <div className='p-1.5 w-full inline-block align-middle'>
                <div className='w-full p-6 m-auto bg-white rounded-md md:max-w-md'>
                  <form
                    className='flex flex-col items-center mt-6 '
                    onSubmit={handleSubmit}>
                    {/* name */}
                    <div className='w-full mb-2'>
                      <span className='text-black'>Name</span>
                      <input
                        type='text'
                        name='Name'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className='block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border '
                      />
                    </div>

                    {/* error message */}
                    {errorMessage && (
                      <p className='px-3 py-2 mt-2 text-white bg-red-400 rounded-md'>
                        {errorMessage}
                      </p> // Display error message
                    )}

                    {/* submit button */}
                    <div className='mt-8 '>
                      <button
                        style={{ backgroundColor: currentColor }}
                        type='submit'
                        className='h-10 px-10 text-white transition-colors duration-150 rounded-lg focus:shadow-outline'>
                        {loading ? (
                          <div className='flex items-center justify-center w-full'>
                            <ThreeDots
                              color='#ffffff'
                              height={20}
                              width={40}
                              className='py-2'
                            />
                          </div>
                        ) : (
                          "Update Interest"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl'>
          <h5 className='font-bold text-center text-md'>Loading ....</h5>
        </div>
      )}
      {/* Success Modal */}
      {showSuccessModal && (
        <div className='fixed inset-0 z-50 flex items-center justify-center'>
          <div className='flex flex-col items-center p-8 bg-white rounded-lg shadow-lg'>
            <FaCheckCircle
              color={currentColor}
              className={`text-[${currentColor}] text-4xl mb-4`}
            />{" "}
            {/* Use currentColor */}
            <p className='text-lg text-black'>{successMessage}</p>{" "}
            {/* Set text color to black */}
            <button
              onClick={() => setShowSuccessModal(false)}
              className={`mt-4 px-4 py-2 bg-[${currentColor}] text-white rounded-md  focus:outline-none`} // Use currentColor for background
            >
              Okay
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default InterestCategoryUpdate;
