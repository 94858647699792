// MentorContext.js

import React, { createContext, useCallback, useContext, useState } from "react";
import ApiTemplate from "../apis/api_template";
import FormData from "form-data";

const MentorContext = createContext();

export const MentorContextProvider = ({ children }) => {
  const [allMentors, setAllMentors] = useState(null);
  const [allInterests, setAllInterests] = useState(null);
  const [interestBuckets, setInterestBuckets] = useState(null);

  const getAllInterests = async (token) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate(
        "get",
        "/api/get-interests",
        {},
        headers
      );
      if (response["success"] === true) {
        console.log(response["data"]);
        setAllInterests(response["data"]);
      } else {
        console.log(response["message"], response.message);
        alert(response["message"]);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const getAllInterestBuckets = async (token, id) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate(
        "get",
        "/api/sub-buckets-by-id/" + id,
        {},
        headers
      );
      if (response["success"] === true) {
        console.log(response["data"]);
        setInterestBuckets(response["data"]);
      } else {
        console.log(response["message"], response.message);
        alert(response["message"]);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const getAllMentors = useCallback(async (token) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate("get", "/api/mentor", {}, headers);
      if (response["success"] === true) {
        console.log(response["data"]);
        setAllMentors(response["data"]);
      } else {
        console.log(response["message"], response.message);
        alert(response["message"]);
      }
    } catch (error) {
      // console.error("Error during API call:", error.toString());
    }
  }, [
    setAllMentors,
  ]);

  const addMentor = async (token, image, name) => {
    try {
      const headers = {
        "Content-Type": "multipart/form-data",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      var data = new FormData();
      data.append("file", image);
      data.append("name", name);

      const response = await ApiTemplate(
        "post",
        "/api/mentor",
        data,
        headers
      );
      if (response["success"] === true) {
        console.log(response["data"]);
        await getAllMentors(token);
        return true;
      } else {
        console.log(response["msg"], response.message);
        alert(response["msg"]);
        return false;
      }
    } catch (error) {
      // console.error("Error during API call:", error.toString());
      return false;
    }
  };

  const addMentorTask = async (
    token,
    image,
    name,
    interestId,
    subBucketId,
    mentorId
  ) => {
    try {
      const headers = {
        "Content-Type": "multipart/form-data",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      var data = new FormData();
      data.append("file", image);
      data.append("name", name);
      data.append("interest_id", interestId);
      data.append("sub_bucket_id", subBucketId);
      data.append("mentor_id", mentorId);

      const response = await ApiTemplate(
        "post",
        "/api/sub-interest",
        data,
        headers
      );
      if (response["success"] === true) {
        console.log(response["data"]);
        await getAllMentors(token);
        return true;
      } else {
        console.log(response["msg"], response.message);
        alert(response["msg"]);
        return false;
      }
    } catch (error) {
      // console.error("Error during API call:", error.toString());
      return false;
    }
  };

  const updateMentor = async (token, data, mentorId) => {
    try {
      const headers = {
        "Content-Type": "multipart/form-data",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate(
        "post",
        "/api/mentor/" + mentorId,
        data,
        headers
      );
      if (response["success"] === true) {
        console.log(response["data"]);
        await getAllMentors(token);
        return true;
      } else {
        console.log(response["msg"], response.message);
        alert(response["msg"]);
        return false;
      }
    } catch (error) {
      // console.error("Error during API call:", error.toString());
      return false;
    }
  };

  const updateMentorTask = async (token, data, mentorId) => {
    try {
      const headers = {
        "Content-Type": "multipart/form-data",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate(
        "post",
        "/api/sub-interest/" + mentorId,
        data,
        headers
      );
      if (response["success"] === true) {
        console.log(response["data"]);
        await getAllMentors(token);
        return true;
      } else {
        console.log(response["msg"], response.message);
        alert(response["msg"]);
        return false;
      }
    } catch (error) {
      // console.error("Error during API call:", error.toString());
      return false;
    }
  };

  return (
    <MentorContext.Provider
      value={{
        getAllInterests,
        getAllInterestBuckets,
        allInterests,
        setAllInterests,
        interestBuckets,
        setInterestBuckets,
        getAllMentors,
        allMentors,
        addMentor,
        updateMentor,
        addMentorTask,
        updateMentorTask,
      }}
    >
      {children}
    </MentorContext.Provider>
  );
};

export const useMentorContext = () => {
  const mentorContextValue = useContext(MentorContext);
  return mentorContextValue;
};
