import React, { createContext, useContext, useState } from "react";
import ApiTemplate from "../apis/api_template";

const TaskContext = createContext();

export const TasksContextProvider = ({ children }) => {
  const [allTasks, setAllTasks] = useState(null);

  const getAllTasks = async (token) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };
  
      const response = await ApiTemplate(
        "get",
        "/api/sub-interest",
        {},
        headers
      );
      
      if (Array.isArray(response.data)) {
        const tasksWithTaskNo = response.data.map((task, index) => ({
          ...task,
          task_no: index + 1
        }));        
        setAllTasks(tasksWithTaskNo);
      } else {
        console.error("Invalid response format. Expected an array.");
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const updateTaskDetails = async (token, data, id) => {
    try {
      const headers = {
        "Content-Type": "multipart/form-data",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate(
        "post",
        "/api/sub-interest/" + id,
        data,
        headers
      );
      if (response["success"] === true) {
        console.log(response);
        await getAllTasks(token);
        return true;
      } else {
        console.log(response["msg"], response.msg);
        alert(response["msg"]);
        return false;
      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;
    }
  };

  const addTask = async (
    token,
    image,
    name,
    time,
    interestId,
    subBucketId,
  ) => {
    try {
      const headers = {
        "Content-Type": "multipart/form-data",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      var data = new FormData();
      data.append("file", image);
      data.append("name", name);
      data.append("time", time);
      data.append("interest_id", interestId);
      data.append("sub_bucket_id", subBucketId);

      const response = await ApiTemplate(
        "post",
        "/api/sub-interest",
        data,
        headers
      );
      if (response["success"] === true) {
        console.log(response["data"]);
        await getAllTasks(token);
        return true;
      } else {
        console.log(response["msg"], response.message);
        alert(response["msg"]);
        return false;
      }
    } catch (error) {
      // console.error("Error during API call:", error.toString());
      return false;
    }
  };

  const deleteEverything = async (token, id) => {
    try {
      const headers = {
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate(
        "delete",
        "/api/sub-interest/" + id,
        {},
        headers
      );
      if (response["success"] === true) {
        if(response["msg"] === "Sub Interest deleted successfully") {
          alert("Task deleted Successfully");
        }
        console.log(response);
        return true;
      } else {
        console.log(response["msg"], response.msg);
        alert(response["msg"]);
        return false;
      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;
    }
  };

  return (
    <TaskContext.Provider
      value={{
        deleteEverything,
        updateTaskDetails,
        getAllTasks,
        addTask,
        allTasks,
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};

export const useTasksContext = () => {
  const taskContextValue = useContext(TaskContext);
  return taskContextValue;
};