import React, { useCallback, useEffect, useState, useRef } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
} from "@syncfusion/ej2-react-grids";

import { Link, useLocation, useParams } from "react-router-dom";
import { subInterestTasksGrid } from "../data/dummy";
import { Header } from "../components";
import { useThemeContext } from "../contexts/ThemeContextProvider";
import { useInterestsContext } from "../contexts/InterestsContextProvider";
import { useAuthContext } from "../contexts/AuthContextProvider";
import DeleteConfirmationModal from "../components/DeleteConfirmationModel";

const InterestSubCategoryTasks = () => {
  const toolbarOptions = ["Search"];
  const { currentColor } = useThemeContext();
  const editing = { allowDeleting: true, allowEditing: true };
  const { subBucketId } = useParams();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteInterestId, setDeleteInterestId] = useState(null);
  const [interestCategory, setInterestCategory] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);
  const [back, setBack] = useState(-1);
  const { getAllInterestBucketTasks, interestBucketTasks, deleteEverything, setInterestBucketTasks } =
    useInterestsContext();
  const { currentUser } = useAuthContext();
  const fetchData = async () => {
    setInterestBucketTasks(null);
    await getAllInterestBucketTasks(currentUser["token"], subBucketId);
    setSuccessDelete(false);
    console.log(successDelete);
  };

  // Ensure fetchData remains stable across renders
  const fetchDataRef = useRef(fetchData);
  useEffect(() => {
    fetchDataRef.current();
  }, []);
  const getInterest = useCallback(() => {
    setInterestCategory(interestBucketTasks);
  }, [interestBucketTasks]);
  useEffect(() => {
    getInterest();
  }, [getInterest]);

  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const deleteId = urlParams.get("delete");
    if (deleteId) {
      console.log("Delete ID:", deleteId);
      setDeleteInterestId(deleteId);
      setBack((prevBack) => prevBack - 1);
      setShowDeleteConfirmation(true);
    } else {
      console.log("No delete ID found.");
    }
  }, [location.search, location]);

  const handleDelete = async (id) => {
    try {
      setDeleteLoading(true);
      // Call the API to delete the sub-bucket
      const result = await deleteEverything(
        currentUser["token"],
        "SubInterest",
        deleteInterestId
      );

      if (!result) {
        throw new Error("Data not Deleted");
      }
      // Refresh the data after successful deletion
      setSuccessDelete(true); // Trigger useEffect after successful deletion
      await getInterest(); // Refresh the interest category data
    } catch (error) {
      console.error("Error during delete:", error);
    } finally {
      // Close the delete confirmation modal
      setShowDeleteConfirmation(false);
      setDeleteInterestId(null);
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete("delete");
      const newUrl = `${window.location.pathname}`;
      window.history.replaceState({}, "", newUrl);
      setDeleteLoading(false);
    }
  };

  return (
    <div className='pt-24 pb-10 bg-gray-100 md:py-10 '>
      {interestCategory ? (
        <div className='p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl'>
          <Header
            category='SubInterest'
            title={"SubBuckets's Tasks"}
            back={back}
          />
          <div className='flex items-center justify-end w-full my-3 mt-5'>
            {/* submit button */}
            <Link to={`add-task`}>
              <button
                style={{ backgroundColor: currentColor }}
                type='submit'
                className='h-10 px-10 text-white transition-colors duration-150 rounded-lg focus:shadow-outline'>
                Add Tasks
              </button>
            </Link>
          </div>
          <GridComponent
            dataSource={interestCategory}
            width='auto'
            allowPaging
            allowSorting
            pageSettings={{ pageCount: 5 }}
            editSettings={editing}
            toolbar={toolbarOptions}>
            <ColumnsDirective>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              {subInterestTasksGrid.map((item, index) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <ColumnDirective key={index} {...item} />
              ))}
            </ColumnsDirective>
            <Inject services={[Search, Page]} />
          </GridComponent>
          {/* Delete Confirmation Modal */}
          <DeleteConfirmationModal
            isOpen={showDeleteConfirmation}
            loading={deleteLoading}
            onClose={() => {
              setShowDeleteConfirmation(false);
              setDeleteInterestId(null);
              const urlParams = new URLSearchParams(window.location.search);
              urlParams.delete("delete");
              const newUrl = `${window.location.pathname}`;
              window.history.replaceState({}, "", newUrl);
            }}
            onDelete={() => handleDelete(deleteInterestId)} // Assuming you want to delete the current interest
          />
        </div>
      ) : (
        <div className='p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl'>
          <h5 className='font-bold text-center text-md'>Loading ....</h5>
        </div>
      )}
    </div>
  );
};
export default InterestSubCategoryTasks;
