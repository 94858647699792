import React, { useCallback, useEffect, useState } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
} from "@syncfusion/ej2-react-grids";
import { Link, useLocation, useParams } from "react-router-dom";
import { subInterestsGrid } from "../data/dummy";
import { Header } from "../components";
import { useThemeContext } from "../contexts/ThemeContextProvider";
import { useInterestsContext } from "../contexts/InterestsContextProvider";
import { useAuthContext } from "../contexts/AuthContextProvider";
import DeleteConfirmationModal from "../components/DeleteConfirmationModel";

const InterestSubCategory = React.memo(() => {
  const toolbarOptions = ["Search"];
  const { currentColor } = useThemeContext();
  const editing = { allowDeleting: true, allowEditing: true };
  const { id } = useParams();

  const [interestCategory, setInterestCategory] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteInterestId, setDeleteInterestId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [back, setBack] = useState(-1);
  const { getAllInterestBuckets, interestBuckets, deleteEverything } =
    useInterestsContext();
  const { currentUser } = useAuthContext();

  const getAllInterest = useCallback(async () => {
    await getAllInterestBuckets(currentUser.token);
  }, [getAllInterestBuckets, currentUser.token]);

  const getInterest = useCallback(() => {
    const interest = interestBuckets?.find(
      (item) => item.id.toString() === id.toString()
    );
    setInterestCategory(interest);
  }, [interestBuckets, id]);

  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const deleteId = urlParams.get("delete");
    if (deleteId) {
      console.log("Delete ID:", deleteId);
      setDeleteInterestId(deleteId);
      setBack((prevBack) => prevBack - 1);
      setShowDeleteConfirmation(true);
    } else {
      console.log("No delete ID found.");
    }
  }, [location.search, location]);

  useEffect(() => {
    getAllInterest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getInterest();
  }, [getInterest]);

  const handleDelete = async (id) => {
    try {
      setDeleteLoading(true);
      const result = await deleteEverything(currentUser.token, "SubBucket", id);
      if (!result) {
        throw new Error("Data not Deleted");
      }
      await getAllInterest();
      await getInterest();
    } catch (error) {
      console.error("Error during delete:", error);
    } finally {
      setShowDeleteConfirmation(false);
      setDeleteInterestId(null);
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete("delete");
      const newUrl = `${window.location.pathname}`;
      window.history.replaceState({}, "", newUrl);
      setDeleteLoading(false);
    }
  };

  return (
    <div className='pt-24 pb-10 bg-gray-100 md:py-10 '>
      {interestCategory ? (
        <div className='p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl'>
          <Header
            category='SubInterest'
            title={interestCategory.name + "'s SubBuckets"}
            back={back}
          />
          <div className='flex items-center justify-end w-full my-3 mt-5'>
            <Link to={`/bucket/${id}/subcategory/add`}>
              <button
                style={{ backgroundColor: currentColor }}
                type='submit'
                className='h-10 px-10 text-white transition-colors duration-150 rounded-lg focus:shadow-outline'>
                Add Sub Buckets
              </button>
            </Link>
          </div>
          <GridComponent
            dataSource={interestCategory.sub_buckets}
            width='auto'
            allowPaging
            allowSorting
            pageSettings={{ pageCount: 5 }}
            editSettings={editing}
            toolbar={toolbarOptions}>
            <ColumnsDirective>
              {subInterestsGrid.map((item, index) => (
                <ColumnDirective key={index} {...item} />
              ))}
            </ColumnsDirective>
            <Inject services={[Search, Page]} />
          </GridComponent>
          <DeleteConfirmationModal
            isOpen={showDeleteConfirmation}
            loading={deleteLoading}
            onClose={() => {
              setShowDeleteConfirmation(false);
              setDeleteInterestId(null);
              const urlParams = new URLSearchParams(window.location.search);
              urlParams.delete("delete");
              const newUrl = `${window.location.pathname}`;
              window.history.replaceState({}, "", newUrl);
            }}
            onDelete={() => handleDelete(deleteInterestId)}
          />
        </div>
      ) : (
        <div className='p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl'>
          <h5 className='font-bold text-center text-md'>Loading ....</h5>
        </div>
      )}
    </div>
  );
});

export default InterestSubCategory;
