import React, { createContext, useContext, useState } from 'react'
import ApiTemplate from '../apis/api_template'

const UserFeedbackContext = createContext()

export const UserFeedbackContextProvider = ({ children }) => {
  const [allUserFeedback, setAllUserFeedback] = useState(null)

  // function formatDateToDayMonthYear (date) {
  //   const day = date.getDate().toString().padStart(2, '0')
  //   const month = (date.getMonth() + 1).toString().padStart(2, '0') // Months are zero-based
  //   const year = date.getFullYear()

  //   return `${day}-${month}-${year}`
  // }

  const convertToFormattedDate = inputDate => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]

    const dateParts = inputDate.split(/[/-]/)
    const year = dateParts[0]
    const month = months[parseInt(dateParts[1], 10) - 1]
    const day = parseInt(dateParts[2], 10)

    return `${day} ${month} ${year}`
  }



  const getAllUserFeedback = async token => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute('content'),
        Authorization: `Bearer ${token}`
      }

      const response = await ApiTemplate('get', '/api/user-feedback', {}, headers)
      if (response) {
        const mergedUserData = response['feedbacks'].map(user => ({
          ...user,
          "created_at": convertToFormattedDate(user["created_at"].split("T")[0])
        }))
        setAllUserFeedback(mergedUserData)
      } else {
        console.log(response['message'])
        alert(response['message'])
      }
    } catch (error) {
      console.error('Error during API call:', error)
    }
  }



  return (
    <UserFeedbackContext.Provider
      value={{ getAllUserFeedback, allUserFeedback, }}
    >
      {children}
    </UserFeedbackContext.Provider>
  )
}

export const useUserFeedbackContext = () => {
  const userFeedBackContextValue = useContext(UserFeedbackContext)
  return userFeedBackContextValue
}
