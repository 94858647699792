const DeleteConfirmationModal = ({ isOpen, onClose, onDelete, loading }) => {
    return (
      <div className={`fixed inset-0 z-50 flex items-center justify-center ${isOpen ? 'block' : 'hidden'}`}>
        <div className="flex flex-col items-center p-8 bg-white rounded-lg shadow-lg">
          <p className="text-lg text-black">Are you sure you want to delete this item?</p>
          <div className="flex mt-4">
            <button
              onClick={onDelete}
              disabled={loading}
              className="px-4 py-2 mr-2 text-white bg-red-500 rounded-md focus:outline-none"
            >

              {loading?"Loading...":"Delete"}
            </button>
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-800 bg-gray-300 rounded-md focus:outline-none"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };
  

  export default DeleteConfirmationModal;