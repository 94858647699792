import React, { useEffect, useRef } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
} from "@syncfusion/ej2-react-grids";
import { userFeedbackGrid } from "../data/dummy";
import { Header } from "../components";
import { useAuthContext } from "../contexts/AuthContextProvider";
import { useUserFeedbackContext } from "../contexts/UserFeedbackProvider";

const UserFeedback = () => {
  const toolbarOptions = ["Search"];
  const { getAllUserFeedback, allUserFeedback } = useUserFeedbackContext();
  const { isLogin, currentUser } = useAuthContext();
  const editing = { allowDeleting: true, allowEditing: true };

  const getAllUserFeedbackRef = useRef(getAllUserFeedback);

  useEffect(() => {
    const fetchData = async () => {
      if (isLogin && currentUser) {
        await getAllUserFeedbackRef.current(currentUser.token);
      }
    };

    fetchData();
  }, [isLogin, currentUser]);

  return (
    <div className='pt-24 pb-10 bg-gray-100 md:py-2 '>
      {allUserFeedback ? (
        <div className='p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl'>
          <Header category='Page' title='User Feedbacks' />

          <GridComponent
            dataSource={allUserFeedback}
            width='100%'
            allowPaging
            allowSorting
            pageSettings={{ pageCount: 5 }}
            editSettings={editing}
            toolbar={toolbarOptions}
          >
            <ColumnsDirective>
              {userFeedbackGrid.map((item, index) => (
                <ColumnDirective key={index} {...item} />
              ))}
            </ColumnsDirective>
            <Inject services={[Search, Page]} />
          </GridComponent>
        </div>
      ) : (
        <div className='p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl'>
          <h5 className='font-bold text-center text-md'>Loading ....</h5>
        </div>
      )}
    </div>
  );
};

export default UserFeedback;