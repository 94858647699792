import React, { useEffect, useState } from "react";
import { BsBoxSeam } from "react-icons/bs";
import { HiOutlineRefresh } from "react-icons/hi";
import { FiBarChart } from "react-icons/fi";
import { MdOutlineSupervisorAccount } from "react-icons/md";
import { useDashboardContext } from "../contexts/DashboardContextProvider";
import { useAuthContext } from "../contexts/AuthContextProvider";

const Ecommerce = () => {
  const { dashboardData, getAllDashboard, usersRecentCompletedTasks } = useDashboardContext();
  const [loading, setLoading] = useState(false);

  const { currentUser } = useAuthContext();

  useEffect(() => {
    localStorage.removeItem("currentPage");
  }, []);

  useEffect(() => {
    getAllDashboard(currentUser["token"]);
  }, [getAllDashboard, currentUser]);

  const handleTaskCompletedClick = async () => {
    setLoading(true);
    try {
      await usersRecentCompletedTasks(currentUser["token"]);
    } catch (error) {
      console.error("Error downloading the CSV file:", error);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="flex flex-col min-h-screen">
      <div className="pt-24 pb-10 flex-grow bg-gray-100 md:py-24">
        {loading ? (
          <div className='p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl'>
            <h5 className='font-bold text-center text-md'>Loading ....</h5>
          </div>
        ) : dashboardData ? (
          <div className='flex flex-wrap items-center justify-center w-full mb-5 lg:flex-shrink lg:flex-nowrap'>
            <div className='w-[90%] lg:w-[80%] mt-3 lg:mt-0 gap-2 flex flex-wrap items-center justify-center lg:flex-shrink lg:flex-nowrap'>
              {[
                {
                  icon: <MdOutlineSupervisorAccount />,
                  amount: dashboardData["users"].toString(),
                  amount1: dashboardData["active-users"].toString(),
                  title: "Users",
                  title1: "Active Users",
                  iconColor: "#03C9D7",
                  iconBg: "#E5FAFB",
                  pcColor: "red-600",
                },
                {
                  icon: <BsBoxSeam />,
                  amount: dashboardData["interests"].toString(),
                  title: "No of Task Completed",
                  iconColor: "rgb(255, 244, 229)",
                  iconBg: "rgb(254, 201, 15)",
                  pcColor: "green-600",
                  onClick: handleTaskCompletedClick,
                },
                {
                  icon: <FiBarChart />,
                  amount: dashboardData["subinterests"].toString(),
                  title: "No of Time on App",
                  iconColor: "rgb(228, 106, 118)",
                  iconBg: "rgb(255, 244, 229)",
                  pcColor: "green-600",
                },
                {
                  icon: <HiOutlineRefresh />,
                  amount: dashboardData["active-users"].toString(),
                  title: "DAU",
                  iconColor: "rgb(0, 194, 146)",
                  iconBg: "rgb(235, 250, 242)",
                  pcColor: "red-600",
                },
              ].map((item, index) => (
                <div
                  key={item.title}
                  className='w-full p-4 bg-white md:w-1/2 lg:w-1/4 h-44 dark:text-gray-200 dark:bg-secondary-dark-bg pt-9 rounded-2xl'>
                  <button
                    type='button'
                    style={{
                      color: item.iconColor,
                      backgroundColor: item.iconBg,
                    }}
                    onClick={item.onClick} 
                    className='text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl'>
                    {item.icon}
                  </button>
                  <div className='flex items-center justify-between'>
                    <p className='mt-3'>
                      <span className='text-lg font-semibold'>
                        {item.amount}
                      </span>
                    </p>
                    {index === 0 && (
                      <p className='mt-3'>
                        <span className='text-lg font-semibold'>
                          {item.amount1}
                        </span>
                      </p>
                    )}
                  </div>
                  <div className='flex items-center justify-between'>
                    <p className='mt-1 text-sm text-gray-400'>{item.title}</p>
                    {index === 0 && (
                      <p className='mt-1 text-sm text-gray-400'>
                        {item.title1}
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className='p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl'>
            <h5 className='font-bold text-center text-md'>Loading ....</h5>
          </div>
        )}
      </div>
    </div>
  );
};

export default Ecommerce;