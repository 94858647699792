import React, { useState, useEffect } from "react";
import { useThemeContext } from "../contexts/ThemeContextProvider";
import ApiTemplate from "../apis/api_template";
import { ThreeDots } from "react-loader-spinner";
import { FiX } from "react-icons/fi"; // Import the close (x) icon from react-icons/fi
import { useAuthContext } from "../contexts/AuthContextProvider";

export default function Login() {
  const { currentColor } = useThemeContext();
  const { handleIsLoginUpdate } = useAuthContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    let timer;
    if (showErrorMessage) {
      timer = setTimeout(() => {
        setShowErrorMessage(false);
      }, 10000); // 40 seconds
    }
    return () => clearTimeout(timer);
  }, [showErrorMessage]);

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    setErrors({ ...errors, email: "" });
  };

  const onChnagePassword = (e) => {
    setPassword(e.target.value);
    setErrors({ ...errors, password: "" });
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage(""); // Clear any previous error messages
    setShowErrorMessage(false);

    // Perform form validation
    const emailError = !email ? "Email is required." : "";
    const passwordError = !password ? "Password is required." : "";

    setErrors({ email: emailError, password: passwordError });

    // Check if there are any validation errors
    if (emailError || passwordError) {
      setLoading(false);
      return;
    }

    try {
      const data = JSON.stringify({
        email: email.toString().trim(),
        password: password.toString().trim(),
      });
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
      };

      const response = await ApiTemplate("post", "/api/login", data, headers);
      if (response["success"] === true) {
        if(response["data"]["user_type"]==="1"){
          await localStorage.setItem("user", JSON.stringify(response["data"]));
          handleIsLoginUpdate(true);
          window.location.href = "/";
        }else{
          setErrorMessage("your are not authorized.becuase you are not admin.");
        setShowErrorMessage(true);
        }
       
      } else {
        setErrorMessage(response["message"]);
        setShowErrorMessage(true);
      }
    } catch (error) {
      console.error("Error during API call:", error);
      // alert("An error occurred during the login process.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
      <div className="w-full p-6 pb-10 m-auto bg-white rounded-md shadow-md lg:max-w-md">
        <div className="flex items-center justify-center w-full">
          <img
            className="w-48 h-48"
            src={require("../data/upticker-icon.png")}
            alt="employee"
          />
        </div>

        <h1
          style={{ color: currentColor }}
          className="text-3xl font-semibold text-center"
        >
          Login
        </h1>
        <form className="mt-6">
          {showErrorMessage && (
            <div className="text-red-600 mb-3 text-sm mt-2 bg-red-200 px-2 py-1 rounded-md flex items-center justify-between">
              {errorMessage}
              <FiX
                className="cursor-pointer"
                onClick={() => setShowErrorMessage(false)}
              />
            </div>
          )}
          <div className="mb-2">
            <label
              htmlFor="email"
              className="block text-sm font-semibold text-gray-800"
            >
              Email
            </label>
            <input
              type="email"
              value={email}
              onChange={onChangeEmail}
              className="block w-full px-4 py-2 mt-2 text-black bg-white border rounded-md focus:border-none"
            />
            {errors.email && (
              <div className="text-red-500 mt-1 text-sm">{errors.email}</div>
            )}
          </div>
          <div className="mb-2">
            <label
              htmlFor="password"
              className="block text-sm font-semibold text-gray-800"
            >
              Password
            </label>
            <input
              type="password"
              value={password}
              onChange={onChnagePassword}
              className="block w-full px-4 py-2 mt-2 text-black bg-white border rounded-md focus:border-none"
            />
            {errors.password && (
              <div className="text-red-500 mt-1 text-sm">{errors.password}</div>
            )}
          </div>

          <div className="mt-6">
            <button
              onClick={submit}
              disabled={loading}
              style={{ backgroundColor: currentColor }}
              className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform rounded-md"
            >
              {loading ? (
                <div className="flex items-center justify-center w-full">
                  <ThreeDots
                    color="#ffffff"
                    height={20}
                    width={40}
                    className="py-2"
                  />
                </div>
              ) : (
                "Login"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
