import React, { createContext, useCallback, useContext, useState } from 'react'
import ApiTemplate from '../apis/api_template'

const UsersContext = createContext()

export const UsersContextProvider = ({ children }) => {
  const [allUsers, setAllUsers] = useState(null)
  const [userInterest, setUserInterest] = useState(null)
  const [lastPageIndex, setLastPageIndex] = useState(5)
  const [allUsersWithoutPagination, setAllUsersWithoutPagination] = useState(null)

  function formatDateToDayMonthYear(date) {
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0') // Months are zero-based
    const year = date.getFullYear()

    return `${day}-${month}-${year}`
  }

  const convertToFormattedDate = inputDate => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]

    const dateParts = inputDate.split(/[/-]/)
    const year = dateParts[2]
    const month = months[parseInt(dateParts[1], 10) - 1]
    const day = parseInt(dateParts[0], 10)

    return `${day} ${month} ${year}`
  }

  const generateRandomUserData = useCallback((user) => {
    const randomReferralCode = Math.random().toString(36).substr(2, 8)
    return {
      Gender: '_',
      DateOfBirth: '_',
      created_at: convertToFormattedDate(
        formatDateToDayMonthYear(new Date(user['created_at']))
      ),
      LastLoginDate: convertToFormattedDate(
        user['last_login']
          ? formatDateToDayMonthYear(
            new Date(user['last_login'])
          )
          : formatDateToDayMonthYear(new Date(user['created_at'].split('T')[0]))
      ),
      ReferralCode: randomReferralCode,
      SuccessfulReferrals: '0',
      SocialMedia: user['login_type']
        ? user['login_type'] === '0'
          ? 'Custom'
          : user['login_type'] === '1'
            ? 'Gmail'
            : 'Apple'
        : '_',
      UserFeedback: user['user_feedback'] && user['user_feedback'].note !== undefined ? user['user_feedback'].note : "N/A",
      TotalNumberOfTasksCreated: String(user['total_tasks']),
      TotalNumberOfTasksCompleted: String(user['completed_tasks']),
      TotalOnTasks: String(user['on_tasks']),
      OverdueTasks: String(
        user['total_tasks'] - user['completed_tasks'] - user['on_tasks']
      ),
      TotalPriorityLevels: `low:${String(
        user['priority_low']
      )}, medium:${String(user['priority_medium'])}, high:${String(
        user['priority_high']
      )}`,
      AppOpened: String(user['total_tasks'] + 5),
      AppSession: '_',
      UserEngagement: '_',
      Address: '_',
      City: user['city'] ? user['city'] : '_',
      PostalCode: '_',
      Country: user['country'] ? user['country'] : '_',
      Location: user['location'] ? user['location'] : '_',
      PhoneNo: '_',
      Height: user['user_info']
        ? JSON.parse(user['user_info'])
          ? JSON.parse(user['user_info'])['height']
            ? String(JSON.parse(user['user_info'])['height'])
            : '_'
          : '_'
        : '_',
      Weight: user['user_info']
        ? JSON.parse(user['user_info'])
          ? JSON.parse(user['user_info'])['weight']
            ? String(JSON.parse(user['user_info'])['weight'])
            : '_'
          : '_'
        : '_',
      Water: user['user_info']
        ? JSON.parse(user['user_info'])
          ? JSON.parse(user['user_info'])['water']
            ? String(JSON.parse(user['user_info'])['water'])
            : '_'
          : '_'
        : '_',
      BMI: user['user_info']
        ? JSON.parse(user['user_info'])
          ? JSON.parse(user['user_info'])['body_mass_index']
            ? String(JSON.parse(user['user_info'])['body_mass_index'])
            : '_'
          : '_'
        : '_',
      HeartRate: user['user_info']
        ? JSON.parse(user['user_info'])
          ? JSON.parse(user['user_info'])['heart_rate']
            ? String(JSON.parse(user['user_info'])['heart_rate'])
            : '_'
          : '_'
        : '_',
      BFP: '_',
      Waist: '_',
      GermanConnected: '_',
      AppleHealth: '_',
      Steps: user['user_info']
        ? JSON.parse(user['user_info'])
          ? JSON.parse(user['user_info'])['steps']
            ? String(JSON.parse(user['user_info'])['steps'])
            : '_'
          : '_'
        : '_',
      Note: `"${user["note"] ? user["note"] : "_"}"`,
    }
  }, [])

  const getAllUsers = useCallback(async (token, currentPage) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute('content'),
        Authorization: `Bearer ${token}`
      };

      const response = await ApiTemplate('get', `/api/all-users?page=${currentPage}`, {}, headers);
      console.log('all users:', response);
      if (response['success'] === true) {
        const mergedUserData = response['data']['data'].map(user => ({
          ...user,
          ...generateRandomUserData(user)
        }));
        console.log("all users merged data:", mergedUserData);
        setAllUsers(mergedUserData);
        setLastPageIndex(response['data']['last_page']);
      } else {
        console.log(response['message']);
        alert(response['message']);
      }
    } catch (error) {
      console.error('Error during API call:', error);
    }
  }, [setAllUsers, generateRandomUserData]);

  const getAllUsersWithoutPagination = useCallback(async (token, currentPage) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute('content'),
        Authorization: `Bearer ${token}`
      };

      const response = await ApiTemplate('get', `/api/users`, {}, headers);
      console.log('all users:', response);
      if (response['success'] === true) {
        const mergedUserData = response['data'].map(user => ({
          ...user,
          ...generateRandomUserData(user)
        }));
        console.log("all users merged data:", mergedUserData);
        setAllUsersWithoutPagination(mergedUserData);
      } else {
        console.log(response['message']);
        alert(response['message']);
      }
    } catch (error) {
      console.error('Error during API call:', error);
    }
  }, [setAllUsersWithoutPagination, generateRandomUserData]);



  const getUserInterest = useCallback(async (token, id) => {
    try {
      function addTime(best_time, how_long) {
        // Parse the best_time string to extract hours and minutes
        if (!best_time || !how_long) {
          return '_'
        }
        const [hours, minutes] = best_time.split(':').map(Number)

        // Convert the best_time to total minutes
        const totalMinutes = hours * 60 + minutes

        // Add the how_long duration in minutes
        const updatedTotalMinutes = totalMinutes + how_long

        // Calculate the updated hours and minutes
        const updatedHours = Math.floor(updatedTotalMinutes / 60)
        const updatedMinutes = updatedTotalMinutes % 60

        // Format the result as a string
        const updatedTime = `${updatedHours}:${updatedMinutes
          .toString()
          .padStart(2, '0')}`

        return updatedTime
      }
      function verifyBucketAndSubBucket(val) {
        if (!val) {
          return '_'
        } else {
          return val
        }
      }

      function calculateTimeDifference(start, end) {
        if (!start || !end) {
          return "_"
        }
        const startDate = new Date(start)
        const endDate = new Date(end)

        if (!startDate || !endDate) {
          return "_"
        }

        const differenceInMilliseconds = endDate - startDate;

        // Convert milliseconds to years, months, days, hours, minutes, and seconds
        const years = Math.floor(
          differenceInMilliseconds / (365.25 * 24 * 60 * 60 * 1000)
        )
        const months = Math.floor(
          (differenceInMilliseconds % (365.25 * 24 * 60 * 60 * 1000)) /
          (30.44 * 24 * 60 * 60 * 1000)
        )
        const days = Math.floor(
          (differenceInMilliseconds % (30.44 * 24 * 60 * 60 * 1000)) /
          (24 * 60 * 60 * 1000)
        )
        const hours = Math.floor(
          (differenceInMilliseconds % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)
        )
        const minutes = Math.floor(
          (differenceInMilliseconds % (60 * 60 * 1000)) / (60 * 1000)
        )
        const seconds = Math.floor(
          (differenceInMilliseconds % (60 * 1000)) / 1000
        )
        if (isNaN(years) || isNaN(months) || isNaN(days) || isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
          return "_"
        }
        // Format the result
        const result = `${days === 0 ? "00" : days}/${months === 0 ? "00" : months}/${years === 0 ? "0000" : years} ${hours === 0 ? "00" : hours}:${minutes === 0 ? "00" : minutes}:${seconds === 0 ? "00" : seconds}`

        return result
      }

      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute('content'),
        Authorization: `Bearer ${token}`
      }

      const response = await ApiTemplate(
        'get',
        '/api/user-interest/' + id.toString(),
        {},
        headers
      )
      if (response['success'] === true) {

        console.log('data without map all', response)
        const mergedUserData = response['data'].map(user => {
          if (!user.status) {
            return {
              ...user,
              status: 'pending',
              due_date: convertToFormattedDate(
                formatDateToDayMonthYear(
                  new Date(
                    user['task_date'] ? user['task_date'] : user['created_at']
                  )
                )
              ),
              estimated_end_time: addTime(user['best_time'], user['how_long']),
              bucket: verifyBucketAndSubBucket(user['bucket']),
              sub_bucket: verifyBucketAndSubBucket(user['sub_bucket']),
              start_time: verifyBucketAndSubBucket(user['start_time']),
              end_time: verifyBucketAndSubBucket(user['end_time']),
              actual_time_spend: calculateTimeDifference(
                verifyBucketAndSubBucket(user['start_time']),
                verifyBucketAndSubBucket(user['end_time'])
              ),
              location: user['location'] ? user['location'] : '_',
              house: user['house'] ? user['house'] : '_',
              street: user['street'] ? user['street'] : '_',
              area: user['area'] ? user['area'] : '_',
              city: user['city'] ? user['city'] : '_',
              post_code: user['post_code'] ? user['post_code'] : '_',
              country: user['country'] ? user['country'] : '_'
            }
          } else {
            return {
              ...user,
              due_date: convertToFormattedDate(
                formatDateToDayMonthYear(
                  new Date(
                    user['task_date'] ? user['task_date'] : user['created_at']
                  )
                )
              ),
              estimated_end_time: addTime(user['best_time'], user['how_long']),
              bucket: verifyBucketAndSubBucket(user['bucket']),
              sub_bucket: verifyBucketAndSubBucket(user['sub_bucket']),
              start_time: verifyBucketAndSubBucket(user['start_time']),
              end_time: verifyBucketAndSubBucket(user['end_time']),
              actual_time_spend: calculateTimeDifference(
                verifyBucketAndSubBucket(user['start_time']),
                verifyBucketAndSubBucket(user['end_time'])
              ),
              location: user['location'] ? user['location'] : '_',
              house: user['house'] ? user['house'] : '_',
              street: user['street'] ? user['street'] : '_',
              area: user['area'] ? user['area'] : '_',
              city: user['city'] ? user['city'] : '_',
              post_code: user['post_code'] ? user['post_code'] : '_',
              country: user['country'] ? user['country'] : '_'
            }
          }
        })
        setUserInterest(mergedUserData)
      } else {
        console.log(response['message'])
        alert(response['message'])
      }
    } catch (error) {
      console.error('Error during API call:', error)
    }
  }, [setUserInterest])

  return (
    <UsersContext.Provider
      value={{ getAllUsers, allUsers, userInterest, getUserInterest, lastPageIndex, allUsersWithoutPagination, getAllUsersWithoutPagination }}
    >
      {children}
    </UsersContext.Provider>
  )
}

export const useUsersContext = () => {
  const usersContextValue = useContext(UsersContext)
  return usersContextValue
}
