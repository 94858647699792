import React, { useCallback, useEffect, useState } from "react";
import avatar3 from "../data/maleAvatar.png";
import { employeeDataView1 } from "../data/dummy";
import { Header } from "../components";
import { useParams } from "react-router-dom";
import { useUsersContext } from "../contexts/UsersContextProvider";
import { useAuthContext } from "../contexts/AuthContextProvider";
import { baseUrl } from "../apis/base_url";
import { ThreeDots } from "react-loader-spinner";
import { useThemeContext } from "../contexts/ThemeContextProvider";

const EmployeeView = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const { allUsers, getAllUsers } = useUsersContext();
  const { currentUser } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const { currentColor } = useThemeContext();

  const getUsers = useCallback(async () => {
    await getAllUsers(currentUser["token"]);
  }, [getAllUsers, currentUser]);

  const getUser = useCallback(async () => {
    const singleUser = allUsers.find(
      (item) => item["id"].toString() === id.toString()
    );
    setUser(singleUser);
  }, [allUsers, id]);

  useEffect(() => getUsers(), [id, getUsers]);
  useEffect(() => getUser(), [allUsers, getUser]);

  const convertToCSV = (user) => {
    const header = Object.keys(user).join(",") + "\n";
    const values = Object.values(user).join(",") + "\n";
    return header + values;
  };

  const downloadCSV = () => {
    setLoading(true);
    const csvContent = "data:text/csv;charset=utf-8," + convertToCSV(user);
    const encodedUri = encodeURI(csvContent);

    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", user["name"] + "user.csv");
    link.setAttribute("target", "_blank"); // Add target attribute for opening in a new tab
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setLoading(false);
  };

  return (
    <div className='pt-24 pb-10 bg-gray-100 md:py-10 '>
      {user ? (
        <div className='p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl'>
          <Header category='User' title={`${user["name"].toUpperCase()}`} />

          {/* image */}
          <div className='w-full mb-4'>
            <div className='flex items-center justify-center gap-2'>
              <img
                className='w-48 h-48 rounded-full'
                src={user["image"] ? baseUrl + "/" + user["image"] : avatar3}
                alt='employee'
              />
            </div>
          </div>

          {/* export to csv button */}
          <div className='flex items-center justify-end w-full my-3 mt-5'>
            <button
              style={{ backgroundColor: currentColor }}
              onClick={downloadCSV}
              disabled={loading}
              className='h-10 px-10 text-white transition-colors duration-150 rounded-lg focus:shadow-outline'>
              {loading ? (
                <ThreeDots color='#fff' height={20} width={40} />
              ) : (
                "Export To CSV"
              )}
            </button>
          </div>

          {/* table */}
          <div className='flex flex-col'>
            <div className='overflow-x-auto'>
              <div className='p-1.5 w-full inline-block align-middle'>
                <div className='overflow-hidden border rounded-lg'>
                  <table className='min-w-full divide-y divide-gray-200'>
                    <tbody className='divide-y divide-gray-200'>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          User ID
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          {user["id"]}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Account Creation Date
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          {user["created_at"].split("T")[0]}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Name
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          {user["name"]}
                        </td>
                      </tr>
                      {/* <tr>
                        <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                          Last Name
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {employeeDataView1.LastName}
                        </td>
                      </tr> */}
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Gender
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          {employeeDataView1.Gender}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Email/Social Id
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          {user["email"]}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Last Login Date
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          {employeeDataView1.LastLoginDate}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Referral Code
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          {employeeDataView1.ReferralCode}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Number of Successful Referrals
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          {employeeDataView1.NumberOfSuccessfulReferrals}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Social Media Integration
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          <ul>
                            <li>
                              Twitter:{" "}
                              {employeeDataView1.SocialMediaIntegration.Twitter}
                            </li>
                            <li>
                              Facebook:{" "}
                              {
                                employeeDataView1.SocialMediaIntegration
                                  .Facebook
                              }
                            </li>
                            <li>
                              Instagram:{" "}
                              {
                                employeeDataView1.SocialMediaIntegration
                                  .Instagram
                              }
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          User Feedback
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          {employeeDataView1.UserFeedback}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Password
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          {employeeDataView1.Password}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Profile Picture
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          <img
                            src={employeeDataView1.ProfilePicture}
                            alt='Profile'
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Date of Birth
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          {employeeDataView1.DateOfBirth}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Total number of tasks created
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          {employeeDataView1.TotalNumberOfTasksCreated}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Total number of tasks completed
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          {employeeDataView1.TotalNumberOfTasksCompleted}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Total time spent on tasks
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          {employeeDataView1.TotalTimeSpentOnTasks}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Total number of tasks overdue
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          {employeeDataView1.TotalNumberOfTasksOverdue}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Total number of tasks with high/medium/low priority
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          High:{" "}
                          {
                            employeeDataView1[
                              "TotalNumberOfTasksWithHigh/Medium/LowPriority"
                            ].High
                          }
                          <br />
                          Medium:{" "}
                          {
                            employeeDataView1[
                              "TotalNumberOfTasksWithHigh/Medium/LowPriority"
                            ].Medium
                          }
                          <br />
                          Low:{" "}
                          {
                            employeeDataView1[
                              "TotalNumberOfTasksWithHigh/Medium/LowPriority"
                            ].Low
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Number of times app opened
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          {employeeDataView1.NumberOfTimesAppOpened}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Time spent on app per session
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          {employeeDataView1.TimeSpentOnAppPerSession}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          User engagement metrics
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          Frequency of app use:{" "}
                          {
                            employeeDataView1.UserEngagementMetrics
                              .FrequencyOfAppUse
                          }
                          <br />
                          Active users:{" "}
                          {employeeDataView1.UserEngagementMetrics.ActiveUsers}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Address
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          {employeeDataView1.Address}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          City
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          {employeeDataView1.City}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Postal Code
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          {employeeDataView1.PostalCode}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Country
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          {employeeDataView1.Country}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Location
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          Latitude: {employeeDataView1.Location.Latitude}
                          <br />
                          Longitude: {employeeDataView1.Location.Longitude}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Contact Information
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          Phone number:{" "}
                          {employeeDataView1.ContactInformation.PhoneNumber}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Preferences
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          Language: {employeeDataView1.Preferences.Language}
                          <br />
                          Theme: {employeeDataView1.Preferences.Theme}
                          <br />
                          Notification preferences:
                          <br />
                          Email:{" "}
                          {employeeDataView1.Preferences.NotificationPreferences
                            .Email
                            ? "Enabled"
                            : "Disabled"}
                          <br />
                          Push:{" "}
                          {employeeDataView1.Preferences.NotificationPreferences
                            .Push
                            ? "Enabled"
                            : "Disabled"}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Subscription/Payment Information
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          Subscription Type:{" "}
                          {
                            employeeDataView1["Subscription/PaymentInformation"]
                              .SubscriptionType
                          }
                          <br />
                          Subscription Expiry Date:{" "}
                          {
                            employeeDataView1["Subscription/PaymentInformation"]
                              .SubscriptionExpiryDate
                          }
                          <br />
                          Payment Method:{" "}
                          {
                            employeeDataView1["Subscription/PaymentInformation"]
                              .PaymentMethod
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          User Activity
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          Tasks Completed:
                          <br />
                          {employeeDataView1.UserActivity.TasksCompleted.map(
                            (task, index) => (
                              <div key={index}>
                                Task ID: {task.TaskID}
                                <br />
                                Task Name: {task.TaskName}
                                <br />
                                Task Priority: {task.TaskPriority}
                                <br />
                                Task Completion Date: {task.TaskCompletionDate}
                                <br />
                              </div>
                            )
                          )}
                          Interactions:
                          <br />
                          Messages Sent:{" "}
                          {
                            employeeDataView1.UserActivity.Interactions
                              .MessagesSent
                          }
                          <br />
                          Likes Given:{" "}
                          {
                            employeeDataView1.UserActivity.Interactions
                              .LikesGiven
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Height
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          {employeeDataView1.Height}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Weight
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          {employeeDataView1.Weight}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Body Mass Index (BMI)
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          {employeeDataView1["BodyMassIndex(BMI)"]}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Body Fat Percentage
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          {employeeDataView1.BodyFatPercentage}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Waist Circumference
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          {employeeDataView1.WaistCircumference}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Germin Connected
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          {employeeDataView1.GerminConnected ? "Yes" : "No"}
                        </td>
                      </tr>
                      <tr>
                        <td className='px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap'>
                          Apple Health Connected
                        </td>
                        <td className='px-6 py-4 text-sm text-gray-800 whitespace-nowrap'>
                          {employeeDataView1.AppleHealthConnected
                            ? "Yes"
                            : "No"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl'>
          <h5 className='font-bold text-center text-md'>Loading ....</h5>
        </div>
      )}
    </div>
  );
};
export default EmployeeView;
