import React, { useEffect, useState } from "react";
import { FaCheckCircle, FaEdit } from "react-icons/fa";
import { Header } from "../components";
import { useThemeContext } from "../contexts/ThemeContextProvider";
import {  useNavigate } from "react-router-dom";
import { useAuthContext } from "../contexts/AuthContextProvider";
import { useMentorContext } from "../contexts/MentorContextProvider";
import { ThreeDots } from "react-loader-spinner";

const MentorAdd = () => {
  const { currentColor } = useThemeContext();
  const [selectedImage, setSelectedImage] = useState(null);
  const [name, setName] = useState("");
  const { checkIsLogin, currentUser } = useAuthContext();
  const { addMentor } = useMentorContext();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    checkIsLogin();
  }, [checkIsLogin]);

  useEffect(() => {
    setTimeout(() => {
      setErrorMessage(null);
    }, 5000);
  }, [errorMessage]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (selectedImage !== null && name.trim() !== "") {
      console.log("Name:", name);
      console.log("Selected Image:", selectedImage);
      // Generate a unique filename based on the current date and time
      const currentDate = new Date();
      const timestamp = currentDate.getTime();
      const imageFilename = `mentor_image_${timestamp}.svg`;

      // Convert base64 image data to a Blob
      const imageBlob = await fetch(selectedImage).then((res) => res.blob());

      // Create a File object from the Blob with the generated filename
      const imageFile = new File([imageBlob], imageFilename, {
        type: "image/svg+xml",
      });

      const newMentor = await addMentor(currentUser["token"], imageFile, name);
      console.log("add mentor", newMentor);
      if (newMentor) {
        setSuccessMessage("Mentor added successfully!");
        setShowSuccessModal(true);
        setTimeout(() => {
          setShowSuccessModal(false);
          navigate(-1);
        }, 2000);
      } else {
        setLoading(false);
        setErrorMessage("Got an error! Please try again.");
        // Add this line
      }
    } else {
      setLoading(false);
      setErrorMessage("Please select an image and provide a name."); // Add this line
    }
    setLoading(false);
  };

  return (
    <div className="pt-24 pb-10 bg-gray-100 md:py-10 ">
      <div className="p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl">
        <Header category="Page" title="Add Mentor" />

        {/* Image */}
        <div className="relative w-full mb-4">
          {selectedImage ? (
            <div className="relative flex items-center justify-center gap-2">
              <img
                className="object-cover w-48 h-48 rounded-full"
                src={selectedImage}
                alt="employee"
              />

              {/* Edit Icon */}
              <div className="absolute bottom-0 right-0 flex items-center justify-center w-[90%]">
                <div
                  style={{ backgroundColor: currentColor }}
                  className="p-4 rounded-full cursor-pointer h-min w-min"
                  onClick={() => document.getElementById("fileInput").click()}
                >
                  <FaEdit className="text-xl text-white" />
                </div>
              </div>
            </div>
          ) : (
            <div className="relative flex items-center justify-center gap-2">
              <div className="w-48 h-48 bg-gray-300 rounded-full"></div>
              {/* Edit Icon */}
              <div className="absolute bottom-0 right-0 flex items-center justify-center w-[90%]">
                <div
                  style={{ backgroundColor: currentColor }}
                  className="p-4 rounded-full cursor-pointer h-min w-min"
                  onClick={() => document.getElementById("fileInput").click()}
                >
                  <FaEdit className="text-xl text-white" />
                </div>
              </div>
            </div>
          )}

          {/* Hidden File Input */}
          <input
            id="fileInput"
            type="file"
            accept="image/*"
            className="hidden"
            onChange={handleImageChange}
          />
        </div>

        {/* update form */}
        <div className="flex flex-col">
          <div className="">
            <div className="p-1.5 w-full inline-block align-middle">
              <div className="w-full p-6 m-auto bg-white rounded-md md:max-w-md">
                <form
                  className="flex flex-col items-center justify-center mt-6 "
                  onSubmit={handleSubmit}
                >
                  {/* name */}
                  <div className="w-full mb-2">
                    <span className="text-black">Name</span>
                    <input
                      type="text"
                      name="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border "
                      placeholder="Enter name"
                    />
                  </div>

                  {/* error message */}
                  {errorMessage && (
                    <p className="px-3 py-2 mt-2 text-white bg-red-400 rounded-md">
                      {errorMessage}
                    </p> // Display error message
                  )}

                  {/* submit button */}
                  <div className="mt-8 ">
                    <button
                      style={{ backgroundColor: currentColor }}
                      type="submit"
                      disabled={loading}
                      className="h-10 px-10 text-white transition-colors duration-150 rounded-lg focus:shadow-outline"
                    >
                      {loading ? (
                        <div className="flex items-center justify-center w-full">
                          <ThreeDots
                            color="#ffffff"
                            height={20}
                            width={40}
                            className="py-2"
                          />
                        </div>
                      ) : (
                        "Add Mentor"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Success Modal */}
      {showSuccessModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="flex flex-col items-center p-8 bg-white rounded-lg shadow-lg">
            <FaCheckCircle
              color={currentColor}
              className={`text-[${currentColor}] text-4xl mb-4`}
            />{" "}
            {/* Use currentColor */}
            <p className="text-lg text-black">{successMessage}</p>{" "}
            {/* Set text color to black */}
            <button
              onClick={() => setShowSuccessModal(false)}
              className={`mt-4 px-4 py-2 bg-[${currentColor}] text-white rounded-md  focus:outline-none`} // Use currentColor for background
            >
              Okay
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MentorAdd;
