import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { links } from "../data/dummy";
import upticker_icon from "../data/upticker-icon.png";
import { useThemeContext } from "../contexts/ThemeContextProvider";
// import { useAuthContext } from "../contexts/AuthContextProvider";
import { baseUrl } from "../apis/base_url";
import { useInterestsContext } from "../contexts/InterestsContextProvider";

const Sidebar = () => {
  const location = useLocation();
  const { currentColor, activeMenu, setActiveMenu, screenSize } =
    useThemeContext();
  const { allInterests } = useInterestsContext();
  // console.log("all interest:", allInterests);
  // const { checkIsLogin, isLogin, currentUser } = useAuthContext();

  // useEffect(() => {
  //   checkIsLogin();
  // }, [checkIsLogin]);
  // useEffect(() => {
  //   if (isLogin && currentUser) {
  //     getAllInterests(currentUser["token"]);
  //   }
  // }, [isLogin, currentUser, getAllInterests]);

  // Create state to manage the visibility of sub-items
  const [showSubItems, setShowSubItems] = useState({});

  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const handleNavItemClick = (itemName) => {
    // Create a new object with all values set to false initially
    const updatedShowSubItems = {};
    Object.keys(showSubItems).forEach((item) => {
      updatedShowSubItems[item] = false;
    });

    // Toggle the visibility of the sub-interests for the "interests" link
    if (itemName === "bucket") {
      updatedShowSubItems[itemName] = !showSubItems[itemName];
    } else {
      // Set the clicked item to true to show sub-items
      updatedShowSubItems[itemName] = true;
    }

    setShowSubItems(updatedShowSubItems);
  };

  const activeLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-white text-md m-2";
  const normalLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2";

  return (
    <div className='h-screen pb-10 ml-3 overflow-auto md:overflow-hidden md:hover:overflow-auto'>
      {activeMenu && (
        <>
          <div className='flex items-center justify-between w-full'>
            <Link
              to='/'
              onClick={handleCloseSideBar}
              className='flex items-center gap-3 mt-4 ml-3 text-xl font-extrabold tracking-tight dark:text-white text-slate-900'>
              <img className='w-full h-16 lg:h-20 2xl:h-28' src={upticker_icon} alt='employee' />
              <span>UpTicker</span>
            </Link>
            <TooltipComponent content='Menu' position='BottomCenter'>
              <button
                type='button'
                onClick={() => setActiveMenu(!activeMenu)}
                style={{ color: currentColor }}
                className='block p-3 mt-4 text-xl rounded-full hover:bg-light-gray md:hidden'>
                <MdOutlineCancel />
              </button>
            </TooltipComponent>
          </div>
          <div className='mt-10 '>
            {links.map((item) => (
              <div key={item.title}>
                {item.links.map((link) => (
                  <div key={link.name}>
                    <NavLink
                        to={`/${link.name.split(" ").join("_")}`}
                        onClick={() => {
                          handleNavItemClick(link.name);
                          handleCloseSideBar();
                        }}
                        style={({ isActive }) => ({
                          backgroundColor: (isActive || (location.pathname === "/" && link.name === "dashboard")) ? currentColor : "",
                          color: (isActive || (location.pathname === "/" && link.name === "dashboard")) ? "rgb(255, 255, 255)" : "", // Condition for both background color and text color
                        })}
                        className={({ isActive }) =>
                          isActive ? activeLink : normalLink
                        }>
                        {link.icon}
                        <span className='capitalize'>{link.name}</span>
                    </NavLink>
                    {showSubItems[link.name] &&
                      link.name === "bucket" &&
                      location.pathname.startsWith("/bucket") && (
                        <div className='ml-8'>
                          {allInterests?.map((subLink) => (
                            <NavLink
                              to={`/${link.name}/${subLink["id"]}`}
                              key={subLink["id"]}
                              onClick={handleCloseSideBar}
                              style={({ isActive }) => ({
                                backgroundColor: isActive ? currentColor : "",
                              })}
                              className={({ isActive }) =>
                                isActive ? activeLink : normalLink
                              }>
                              <img
                                className='w-4 h-4'
                                src={baseUrl + "/" + subLink["icon"]}
                                alt='employee'
                              />
                              <span className='capitalize '>
                                {subLink["name"]}
                              </span>
                            </NavLink>
                          ))}
                        </div>
                      )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
