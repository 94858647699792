import React, { useCallback, useEffect, useState, useRef } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Sort
} from "@syncfusion/ej2-react-grids";
import { tasksGrid } from "../data/dummy";
import { Header } from "../components";
import { useAuthContext } from "../contexts/AuthContextProvider";
import DeleteConfirmationModal from "../components/DeleteConfirmationModel";
import { useTasksContext } from "../contexts/TasksContextProvider";
import { Link, useLocation } from "react-router-dom";
import { useThemeContext } from "../contexts/ThemeContextProvider";

const Tasks = () => {
  const toolbarOptions = ["Search"];
  const { currentColor } = useThemeContext();

  const { getAllTasks, allTasks, deleteEverything } = useTasksContext();
  const { checkIsLogin, isLogin, currentUser } = useAuthContext();

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteInterestId, setDeleteInterestId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [back, setBack] = useState(-1);
  const [sortOrder, setSortOrder] = useState("default");

  useEffect(() => {
    localStorage.removeItem("currentPage");
  }, []);

  useEffect(() => {
    checkIsLogin();
  }, [checkIsLogin]);

  const getTasks = useCallback(async () => {
    if (isLogin && currentUser) {
      await getAllTasks(currentUser["token"]);
    }
  }, [getAllTasks, isLogin, currentUser]);

  const fetchDataRef = useRef(getTasks);

  useEffect(() => {
    fetchDataRef.current();
  }, []);

  const editing = { allowDeleting: true, allowEditing: true };
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const deleteId = urlParams.get("delete");
    if (deleteId) {
      console.log("Delete ID:", deleteId);
      setDeleteInterestId(deleteId);
      setBack((prevBack) => prevBack - 1);
      setShowDeleteConfirmation(true);
    } else {
      console.log("No delete ID found.");
    }
  }, [location.search, location]);

  const handleDelete = async (id) => {
    try {
      setDeleteLoading(true);
      const result = await deleteEverything(currentUser["token"], id);

      if (!result) {
        throw new Error("Data not Deleted");
      }
      await getAllTasks(currentUser["token"]);
    } catch (error) {
      console.error("Error during delete:", error);
    } finally {
      setShowDeleteConfirmation(false);
      setDeleteInterestId(null);
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete("delete");
      const newUrl = `${window.location.pathname}`;
      window.history.replaceState({}, "", newUrl);
      setDeleteLoading(false);
    }
  };

  const sortedTasks = allTasks ? (sortOrder === "asc" ? allTasks.slice().sort((a, b) => a.name.localeCompare(b.name)) : (sortOrder === "desc" ? allTasks.slice().sort((a, b) => b.name.localeCompare(a.name)) : allTasks.slice())) : [];

  const toggleSortOrder = (event) => {
    const selectedOrder = event.target.value;
    if (selectedOrder === "default") {
      setSortOrder("default");
    } else {
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    }
  };

  return (
    <div className='pt-24 pb-10 bg-gray-100 md:py-2 '>
      {sortedTasks ? (
        <div className='p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl'>
          <Header category='Page' title='Tasks' back={back} />
          <div className='flex items-center justify-end w-full my-3 mt-5'>
            <Link to='/tasks/add'>
              <button
                style={{ backgroundColor: currentColor }}
                type='submit'
                className='h-10 px-10 text-white transition-colors duration-150 rounded-lg focus:shadow-outline mr-2'>
                Add Task
              </button>
            </Link>
            <select
              value={sortOrder}
              onChange={toggleSortOrder}
              className="h-10 px-4 text-sm rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            >
              <option value="default">Default</option>
              <option value="asc">Sort A-Z</option>
              <option value="desc">Sort Z-A</option>
            </select>
            {/* <Link to='/mentors/add'>
              <button
                style={{ backgroundColor: currentColor }}
                type='submit'
                className='h-10 px-10 text-white transition-colors duration-150 rounded-lg focus:shadow-outline'>
                Add Tasks
              </button>
            </Link> */}
          </div>
          <GridComponent
            dataSource={sortedTasks}
            width='auto'
            allowPaging
            allowSorting
            pageSettings={{ pageCount: 5 }}
            editSettings={editing}
            toolbar={toolbarOptions}>
            <ColumnsDirective>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              {tasksGrid.map((item, index) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <ColumnDirective key={index} {...item} />
              ))}
            </ColumnsDirective>
            <Inject services={[Search, Page, Sort]} />
          </GridComponent>

          {/* Delete Confirmation Modal */}
          <DeleteConfirmationModal
            isOpen={showDeleteConfirmation}
            loading={deleteLoading}
            onClose={() => {
              setShowDeleteConfirmation(false);
              setDeleteInterestId(null);
              const urlParams = new URLSearchParams(window.location.search);
              urlParams.delete("delete");
              const newUrl = `${window.location.pathname}`;
              window.history.replaceState({}, "", newUrl);
            }}
            onDelete={() => handleDelete(deleteInterestId)} 
          />
        </div>
      ) : (
        <div className='p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl'>
          <h5 className='text-center font-bold text-md'>Loading ....</h5>
        </div>
      )}
    </div>
  );
};
export default Tasks;
