import React, { useCallback, useEffect, useState } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { employeesGrid } from "../data/dummy";
import { Header } from "../components";
import { useUsersContext } from "../contexts/UsersContextProvider";
import { useAuthContext } from "../contexts/AuthContextProvider";
import { useThemeContext } from "../contexts/ThemeContextProvider";
import { ThreeDots } from "react-loader-spinner";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import Papa from 'papaparse';

const Employees = () => {
  // const toolbarOptions = ["Search"];
  const { currentColor } = useThemeContext();
  const {
    allUsers,
    getAllUsers,
    lastPageIndex,
    allUsersWithoutPagination,
    getAllUsersWithoutPagination,
  } = useUsersContext();
  const { currentUser } = useAuthContext();
  const editing = { allowDeleting: true, allowEditing: true };
  const [loading, setLoading] = useState(false);
  const toolbarOptions = ["Search"];
  const [currentPage, setCurrentPage] = useState(() => {
    const storedPage = localStorage.getItem("currentPage");
    console.log("stored page", storedPage);
    return storedPage ? parseInt(storedPage, 10) : 1;
  });
  const [downloadCSVFlag, setDownloadCSVFlag] = useState(false);
  const [downloadNow, setDownloadNow] = useState(false);

  console.log("last page index:", lastPageIndex);
  const handlePageChange = (args) => {
    if (args.requestType === "paging") {
      console.log("Page changed", args);
      setCurrentPage(args.currentPage);
    }
  };
  const handleCustomPageChange = (page) => {
    setCurrentPage(page);
  };

  const getUsers = useCallback(async () => {
    await getAllUsers(currentUser["token"], currentPage);
  }, [getAllUsers, currentUser, currentPage]);

  useEffect(() => getUsers(), [getUsers]);

  useEffect(() => {
    console.log("running effect");
    localStorage.setItem("currentPage", currentPage?.toString());
  }, [currentPage]);

  const splitName = (name) => {
    const nameParts = name.split(" ");

    let firstName = ""; // Initialize with an empty string
    let lastName = "";

    if (nameParts.length === 1) {
      // If there's only one part, consider it the first name
      firstName = nameParts[0];
    } else {
      // If there are multiple parts, consider the first part as the first name
      firstName = nameParts[0];
      // Join the rest as the last name
      lastName = nameParts.slice(1).join(" ");
    }
    return {
      firstName,
      lastName,
    };
  };

  const fetchAllUsers = useCallback(async () => {
    await getAllUsersWithoutPagination(currentUser["token"]);
    setDownloadNow(true);
  }, [getAllUsersWithoutPagination, currentUser]);

  useEffect(() => {
    if (downloadCSVFlag) {
      setLoading(true);
      fetchAllUsers();
      setDownloadCSVFlag(false);
    }
  }, [downloadCSVFlag, fetchAllUsers]);

  const downloadCSV = useCallback(() => {
    setLoading(true);

    const data = allUsersWithoutPagination.map((user) => {
      return {
        "User Id": user["id"],
        "Account Creation Date": user["created_at"],
        "First Name": splitName(user["name"])["firstName"],
        "Last Name": splitName(user["name"])["lastName"],
        "Email Address": user["email"],
        Location: user["Location"],
        Country: user["Country"],
        "Total Number Of Tasks Completed": user["TotalNumberOfTasksCompleted"],
        "User Feedback": user["UserFeedback"],
        Gender: user["Gender"],
        "Last Login Date": user["LastLoginDate"] ? user["LastLoginDate"] : "_",
        "Refferal Code": user["ReferralCode"],
        "Number of successful refferals": user["ReferralCode"],
        "Social Media integration": user["SocialMedia"] === 'Custom' ? 'Email' : user["SocialMedia"],
        "Date Of Birth": user["DateOfBirth"],
        "Total Number Of Tasks Created": user["TotalNumberOfTasksCreated"],
        "Total time spend on tasks": user["TotalNumberOfTasksCompleted"],
        "Time spend on app": "_",
        "Total number of tasks overdue": user["OverdueTasks"],
        "Total number of tasks with high/medium/low priority": `low:${String(
          user["priority_low"]
        )}-medium:${String(user["priority_medium"])}-high:${String(
          user["priority_high"]
        )}`,
        "Number of times app opened": user["AppOpened"],
        "Time spent on app per session": user["AppSession"],
        "User engagement metrix": user["UserEngagement"],
        Address: user["Address"],
        City: user["City"],
        "Postal code": user["PostalCode"],
        "Contact Information": user["PhoneNo"],
        Preferences: "_",
        Height: user["Height"],
        Weight: user["Weight"],
        Water: user["Water"],
        Steps: user["Steps"],
        "Heart Rate": user["HeartRate"],
        "Body Mass Index": user["BMI"],
        "Body Fat Percentage": user["BFP"],
        "Waiste circumference": user["Waist"],
        "Germin connected": user["GermanConnected"],
        "Apple Health Connected": user["AppleHealth"],
        Note: `"${user["note"] ? user["note"] : "_"}"`,
      };
    });

    const csv = Papa.unparse(data);

    const blob = new Blob([csv], { type: 'text/csv' });

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "users.csv");
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

    setLoading(false);
}, [allUsersWithoutPagination, ]);

  useEffect(() => {
    if (downloadNow) {
      downloadCSV();
      setDownloadNow(false);
    }
  }, [downloadNow, downloadCSV]);

  return (
    <>
      <div className='pt-24 pb-10 bg-gray-100 md:py-2'>
        {allUsers ? (
          <div className='p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl max-w-min'>
            <Header category='Page' title='Users' />
            {/* export to csv button */}
            <div className='flex items-center justify-end w-full my-3 mt-5'>
              <button
                style={{ backgroundColor: currentColor }}
                onClick={() => {
                  setDownloadCSVFlag(true);
                }}
                disabled={loading}
                className='h-10 px-10 text-white transition-colors duration-150 rounded-lg focus:shadow-outline'>
                {loading ? (
                  <ThreeDots color='#fff' height={20} width={40} />
                ) : (
                  "Export To CSV"
                )}
              </button>
            </div>
            <GridComponent
              dataSource={allUsers}
              width='100%'
              allowPaging={false}
              allowSorting
              editSettings={editing}
              toolbar={toolbarOptions}
              actionComplete={handlePageChange}>
              <ColumnsDirective>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                {employeesGrid.map((item, index) => (
                  <ColumnDirective key={index} {...item} />
                ))}
              </ColumnsDirective>
              <Inject services={[Toolbar, Page]} />
            </GridComponent>
            <div className='pagination'>
              <button
                onClick={() => handleCustomPageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className={`pagination-button ${
                  currentPage === 1 ? "disabled" : ""
                }`}>
                <FiChevronLeft />
              </button>
              {Array.from({ length: lastPageIndex }, (_, i) => i + 1).map(
                (page) => (
                  <button
                    key={page}
                    onClick={() => handleCustomPageChange(page)}
                    className={`pagination-button ${
                      page === currentPage ? "active" : ""
                    }`}>
                    {page}
                  </button>
                )
              )}
              <button
                onClick={() => handleCustomPageChange(currentPage + 1)}
                disabled={currentPage === lastPageIndex}
                className={`pagination-button ${
                  currentPage === lastPageIndex ? "disabled" : ""
                }`}>
                <FiChevronRight />
              </button>
            </div>
          </div>
        ) : (
          <div className='p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl'>
            <h5 className='font-bold text-center text-md'>Loading ....</h5>
          </div>
        )}
      </div>
    </>
  );
};
export default Employees;
