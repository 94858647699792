import React from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineArrowBack } from "react-icons/md";
import { useThemeContext } from "../contexts/ThemeContextProvider";

const Header = ({ category, title, back=-1 }) => {
  const { currentColor } = useThemeContext();
  const navigate = useNavigate();
  
  const handleGoBack = () => {
    navigate(back);
  };
  return (
    <div className="flex items-start justify-start mb-2">
      <button
        onClick={handleGoBack}
        style={{ backgroundColor: currentColor }}
        className="p-2 mr-3 rounded-full "
      >
        <MdOutlineArrowBack size={20} color="#ffff" />
      </button>
      <div>
        {/* <p className="text-lg text-gray-400">{category}</p> */}
        <p className="text-3xl font-extrabold tracking-tight text-slate-900">
          {title}
        </p>
      </div>
    </div>
  );
};

export default Header;
