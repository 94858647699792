import React, { useCallback, useEffect, useState, useRef } from "react";
import { FaCheckCircle, FaEdit } from "react-icons/fa";
import { Header } from "../components";
import { useThemeContext } from "../contexts/ThemeContextProvider";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../contexts/AuthContextProvider";
import { useTasksContext } from "../contexts/TasksContextProvider";
import { useMentorContext } from "../contexts/MentorContextProvider";
import { baseUrl } from "../apis/base_url";
import { ThreeDots } from "react-loader-spinner";
import TimePicker from "react-time-picker";

const TasksUpdate = () => {
  const { id } = useParams();
  const { currentColor } = useThemeContext();
  const [selectedImage, setSelectedImage] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [time, setTime] = useState("");

  const navigate = useNavigate();
  const { checkIsLogin, isLogin, currentUser } = useAuthContext();
  const { allTasks, getAllTasks, updateTaskDetails } = useTasksContext();
  const {
    getAllInterests,
    getAllInterestBuckets,
    allInterests,
    setAllInterests,
    interestBuckets,
    setInterestBuckets
  } = useMentorContext();


  const fetchTasksRef = useRef();
  const [taskDetails, setTaskDetails] = useState(null);
  const [selectedInterest, setSelectedInterest] = useState(null);
  const [selectedInterestBucket, setSelectedInterestBucket] = useState(null);

  const handleInterestChange = async (event) => {
    const selectedInterestId = event.target.value;
    setSelectedInterest(selectedInterestId);
    setSelectedInterestBucket("");
    if (selectedInterestId) {
      await getAllInterestBuckets(currentUser["token"], selectedInterestId);
    } else {
    }
  };

  const handleInterestBucketChange = (event) => {
    setSelectedInterestBucket(event.target.value);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    checkIsLogin();

    return () => {
      checkIsLogin();
    };
  }, [checkIsLogin]);

  useEffect(() => {
    const fetchTasks = async () => {
      if (!allTasks && isLogin && currentUser) {
        await getAllTasks(currentUser["token"]);
      }
    };
    fetchTasks();
  }, [getAllTasks, allTasks, currentUser, isLogin]);

  fetchTasksRef.current = useCallback(async () => {
    if (id && allTasks) {
      const task = allTasks.find(
        (item) => item["id"].toString() === id.toString()
      );
      if (!allInterests) {
        await getAllInterests(currentUser["token"]);
      }
      if (!interestBuckets && task) {
        await getAllInterestBuckets(
          currentUser["token"],
          task["interest_id"]
        );
      }
      setTaskDetails(task)
      setSelectedInterest(task["interest_id"]);
      setSelectedInterestBucket(task["sub_bucket_id"]);
    }
  }, [
    id,
    allTasks,
    allInterests,
    interestBuckets,
    currentUser,
    getAllInterests,
    getAllInterestBuckets,
  ]);

  useEffect(() => {
    fetchTasksRef.current();

    return() => {
      setAllInterests(null);
      setInterestBuckets(null)
    }
  }, [setAllInterests, setInterestBuckets]);

  const onTaskDataChange = useCallback(async () => {
    if (taskDetails) {
      setName(taskDetails["name"]);
      setTime(taskDetails["time"]);
    }
  }, [taskDetails]);

  useEffect(() => {
    onTaskDataChange();
  }, [onTaskDataChange]);

  useEffect(() => {
    setTimeout(() => {
      setErrorMessage(null);
    }, 5000);
  }, [errorMessage]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    var data = new FormData();

    if (selectedImage == null) {
      data.append("name", name.toString());
      data.append("interest_id", selectedInterest.toString());
      data.append("sub_bucket_id", selectedInterestBucket.toString());
    } else {
      const currentDate = new Date();
      const timestamp = currentDate.getTime();
      const imageFilename = `task_image_${timestamp}.png`;

      const imageBlob = await fetch(selectedImage).then((res) => res.blob());

      const imageFile = new File([imageBlob], imageFilename, {
        type: "image/png",
      });

      data.append("file", imageFile);
      data.append("name", name.toString());
      data.append("interest_id", selectedInterest.toString());
      data.append("sub_bucket_id", selectedInterestBucket.toString());
    }
    data.append("_method", "put");
    time && data.append("time", time);
    const newTask = await updateTaskDetails(
      currentUser["token"],
      data,
      id
    );
    if (newTask) {
      setSuccessMessage("Task Updated successfully!");
      setShowSuccessModal(true);
      setTimeout(() => {
        setShowSuccessModal(false);
        navigate(-1);
      }, 2000);
    } else {
      setLoading(false);
      setErrorMessage("Got an error! Please try again.");
    }
    setLoading(false);
  };
  return (
    <div className='pt-24 pb-10 bg-gray-100 md:py-10 '>
      {taskDetails ? (
        <div className='p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl'>
          <Header category='Page' title="Update Task" />

          {/* Image */}
          <div className='relative w-full mb-4'>
            {selectedImage ? (
              <div className='relative flex items-center justify-center gap-2'>
                <img
                  className='w-48 h-48 rounded-full'
                  src={selectedImage}
                  alt='employee'
                />

                {/* Edit Icon */}
                <div className='absolute bottom-0 right-0 flex items-center justify-center w-[90%]'>
                  <div
                    style={{ backgroundColor: currentColor }}
                    className='p-4 rounded-full cursor-pointer h-min w-min'
                    onClick={() =>
                      document.getElementById("fileInput").click()
                    }>
                    <FaEdit className='text-xl text-white' />
                  </div>
                </div>
              </div>
            ) : (
              <div className='relative flex items-center justify-center gap-2'>
                <img
                  className='w-48 h-48 rounded-full'
                  src={baseUrl + "/" + taskDetails["icon"]}
                  alt='employee'
                />
                {/* Edit Icon */}
                <div className='absolute bottom-0 right-0 flex items-center justify-center w-[90%]'>
                  <div
                    style={{ backgroundColor: currentColor }}
                    className='p-4 rounded-full cursor-pointer h-min w-min'
                    onClick={() =>
                      document.getElementById("fileInput").click()
                    }>
                    <FaEdit className='text-xl text-white' />
                  </div>
                </div>
              </div>
            )}

            {/* Hidden File Input */}
            <input
              id='fileInput'
              type='file'
              accept='image/*'
              className='hidden'
              onChange={handleImageChange}
            />
          </div>

          {/* update form */}
          <div className='flex flex-col'>
            <div className=''>
              <div className='p-1.5 w-full inline-block align-middle'>
                <div className='w-full p-6 m-auto bg-white rounded-md md:max-w-md'>
                  <form
                    className='flex flex-col items-center mt-6 '
                    onSubmit={handleSubmit}
                  >
                    {/* name */}
                    <div className='w-full mb-2'>
                      <span className='text-black'>Name</span>
                      <input
                        type='text'
                        name='Name'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className='block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border '
                      />
                    </div>

                    {/* Select Interest Dropdown */}
                    <div className='w-full mb-2'>
                      <span className='text-black'>Select a Bucket</span>
                      <select
                        value={selectedInterest}
                        onChange={handleInterestChange}
                        className='block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border'>
                        <option value=''>Select Bucket</option>
                        {allInterests &&
                          allInterests.map((interest) => (
                            <option key={interest.id} value={interest.id}>
                              {interest.name}
                            </option>
                          ))}
                      </select>
                    </div>

                    {/* Select Interest Bucket Dropdown */}
                    <div className='w-full mb-2'>
                      <span className='text-black'>
                        Select a Sub-bucket
                      </span>
                      <select
                        value={selectedInterestBucket}
                        onChange={handleInterestBucketChange}
                        className='block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border'>
                        <option value=''>Select Sub-Bucket</option>
                        {interestBuckets &&
                          interestBuckets.map((bucket) => (
                            <option key={bucket.id} value={bucket.id}>
                              {bucket.name}
                            </option>
                          ))}
                      </select>
                    </div>

                    {/* time */}
                    <div className='w-full mb-2'>
                      <span className='text-black'>Time</span>
                      <TimePicker
                        className='block w-full px-4 py-2 mt-2 border border-gray-400 rounded-md shadow-sm outline-0 focus:border'
                        onChange={setTime}
                        value={time}
                        clearAriaLabel='Clear value'
                        disableClock={false}
                        format='HH:mm' // Change the format to 24-hour 'HH:mm'
                        locale='en-US'
                        name='Time'
                        placeholder='Select time'
                        required={false}
                        showLeadingZeros={true}
                        clearIcon={null}
                        clockIcon={null}
                        hourPlaceholder='hh'
                        minutePlaceholder='mm'
                        autoFocus
                      />
                    </div>

                    {/* error message */}
                    {errorMessage && (
                      <p className='px-3 py-2 mt-2 text-white bg-red-400 rounded-md'>
                        {errorMessage}
                      </p> // Display error message
                    )}

                    {/* submit button */}
                    <div className='mt-8 '>
                      <button
                        style={{ backgroundColor: currentColor }}
                        type='submit'
                        className='h-10 px-10 text-white transition-colors duration-150 rounded-lg focus:shadow-outline'>
                        {loading ? (
                          <div className='flex items-center justify-center w-full'>
                            <ThreeDots
                              color='#ffffff'
                              height={20}
                              width={40}
                              className='py-2'
                            />
                          </div>
                        ) : (
                          "Update Task"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='p-2 m-2 bg-white md:m-10 md:p-10 rounded-3xl'>
          <h5 className='font-bold text-center text-md'>Loading ....</h5>
        </div>
      )}
      {/* Success Modal */}
      {showSuccessModal && (
        <div className='fixed inset-0 z-50 flex items-center justify-center'>
          <div className='flex flex-col items-center p-8 bg-white rounded-lg shadow-lg'>
            <FaCheckCircle
              color={currentColor}
              className={`text-[${currentColor}] text-4xl mb-4`}
            />{" "}
            {/* Use currentColor */}
            <p className='text-lg text-black'>{successMessage}</p>{" "}
            {/* Set text color to black */}
            <button
              onClick={() => setShowSuccessModal(false)}
              className={`mt-4 px-4 py-2 bg-[${currentColor}] text-white rounded-md  focus:outline-none`} // Use currentColor for background
            >
              Okay
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TasksUpdate;
